.gallery-container{
    display: flex;
    /* max-width: 1210px; */
    margin-top: 40px;
    flex-wrap: wrap;
    justify-content: center;
}

.gallery-container .gallery-box{
    /* width: 121px;
    height: 121px; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #f8f8f8;
    border-radius: 20px;
    box-shadow: 0px 1px 20px 4px rgba(0, 0, 0, 0.03);
    transition: all 0.2s linear;

}

.gallery-container .gallery-box:hover{
    background-color:#ffffff;
    box-shadow: 0 4px 40px 4px rgba(0, 0, 0, 0.13);
    position: relative;
    z-index: 99;
}


.gallery-container .gallery-box img{
    max-width: 70%;
    max-height: 45%;
}



