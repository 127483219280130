
.sharing .sharingTitle:nth-child(3){
    margin-top: 40px;
}
.sharing .sharingTitle{
    font-size: 20px;
    text-align: center;
    line-height: 34px;
    height: 34px;
    font-weight: 400;
    color: #333;
}
.sharing .sharingTitle + .sharingTitle{
    margin-top: 15px;
}
.sharing .sharingCount{
    margin-top: 83px;
    width: 828px;
    color: #333;
}
.sharing .sharingCount div label{
    font-size: 40px;
    text-align: center;
    color: #69c0b4;
    min-width: 100px;
    font-family: DIN Alternate;
    font-style: normal;
    font-weight: bold;
    line-height: 56px;
}
.sharing .sharingCount div label > .plus{
    font-weight: bold;
    font-size: 40px;
    padding-top: 0;
    vertical-align: top;
    font-family: PingFang SC;
    color: #49C5B6;
}
.sharing .sharingCount div label i{
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    position: relative;
    top:-3px;
    color: #49C5B6;
}
.sharing .sharingCount div span{
    padding-top: 15px;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
}
.sharing .sharingCount div span.number{
    padding-top: 0;
    font-size: 56px;
    font-weight: 500;
    line-height: 1;
    color: #49C5B6;
}

.sharing .moreFocuson{
    padding-top: 40px;
    width: 612px;
}

.sharing .moreFocuson div span{
    padding-top: 16px;
}

.sharing .more-image {
    width: 90px;
    height: 90px;
}

.sharing .cus-slice .slick-next {
    right: 61px;
}

.sharing .cover-text-desc {
    width: 62%;
}

.sharing .slick-swiper .slick-list {
    height: 530px;
}

.sharing .Union {
    position: absolute;
    z-index: -1;
    width: 67.71%;
    left: 0;
    top: -10%;
}

.sharing .Subtract {
    position: absolute;
    z-index: -1;
    width: 55%;
    right: -40px;
    top: -10%;
}
