.arcboard {
    width: 100%;
    background-position: center -430px;
    background-size: 1500px auto;
    position: relative;
    margin-top: 50px;
}

.arc-container {
    min-height: 500px;
    position: relative;
    margin: 0 auto;
}

.logo-item {
    width: 159px;
    height: 56px;
    padding: 0 10px;
    background: #333333;
    border-radius: 56px;
    position: absolute;
    left: 50%;
    bottom: 200px;
    transform: translateX(-50%);
}

.logo-item img {
    width: 80%;
    height: 90%;
    object-fit: contain;
}


/* .arc-container::before {
    content: '';
    display: block;
    width: 100%;
    background: linear-gradient(180deg, rgba(60, 207, 195, 0.8) 0%, rgba(196, 196, 196, 0) 21.35%);
    opacity: 0.3;
    top: 110px;
    height: 800px;
    position: absolute;
    border-radius: 50%;
} */

.arc-container .arc-group {
    width: 100%;
    position: relative;
    height: 360px;
}

.arc-container .arc-box {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.05);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s linear;
}

.arc-container .arc-box.show {
    opacity: 1;
}

.arc-container .arc-box img {
    max-width: 70%;
    max-height: 45%;
}