.marketing {
  background-color: #f6f6f6;
}

.marketing .anchor {
  margin-top: 40px;
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  width: 893px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
}

.marketing .anchor .count img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.marketing .anchor .count label {
  line-height: 31px;
  height: 31px;
  font-size: 20px;
}

.marketing .anchor .anchorTxt {
  color: #353535;
  font-size: 29px;
  margin-top: 46px;
  word-break: break-all;
  width: 540px;
  overflow: hidden;
  text-align: left;
  font-weight: 600;
}

.marketing .anchor .anchorTitle {
  height: 43px;
  line-height: 43px;
  font-weight: 600;
  background-color: #333333;
  color: #fff;
  border-radius: 30px;
  padding: 0 15px;
  font-size: 26px;
}

.marketing .anchor .anchorInfo {
  color: #666666;
  margin-top: 46px;
  margin-bottom: 36px;
  width: 540px;
  overflow: hidden;
  text-align: left;
  font-size: 15px;
}

.slick-swiper .slick-slide {
  padding: 0;
}

.marketing .anchor .header {
  border-radius: 20px;
  width: 300px;
  height: 400px;
  overflow: hidden;
  margin-right: 52px;
}

.marketing .nav-head-img{
  width: 100%;
  position: relative;
}
.marketing .nav-head-img img{
  width: 100%;
  vertical-align: bottom;
}

.marketing .nav-head-text {
  width: 216px;
  height: 66px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) matrix(1, 0, -0.1, 1, 0, 0);
}
.marketing .nav-head-text img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.marketing .anchor .header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.marketing .caseInfo {
  width: 1070px;
  margin-top: 40px;
}

.caseInfo .slick-slider.slick-initialized{
  width: 1070px;

}

.marketing .caseInfoItem {
  width: 332px;
  overflow: hidden;
  padding: 0 11px;
}

.marketing .caseInfoItem label {
  font-weight: 600;
  padding-top: 30px;
  padding-left: 20px;
  color: #333333;
}

.marketing .flexrss-count {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.marketing .label-num {
  font-weight: 700;
  font-size: 28px;
  color: #202020;
  font-family: DIN Alternate;
}

.marketing .caseInfoItem p {
  padding: 0 20px;
  text-align: left;
}

.marketing .caseInfoItem .img {
  overflow: hidden;
  border-radius: 26px;
  width: 332px;
  margin: 0;
  padding: 0;
}

.marketing .caseInfoItem .img img {
  width: 100%;
  height: 100%;
}

.caseInfo .slick-prev,
.caseInfo .slick-next {
  background: #333;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 100;
  opacity: 1;
}
.caseInfo .slick-prev:hover,
.caseInfo .slick-next:hover {
background: #49C5B6;
}
.caseInfo .slick-arrow.slick-prev{
  left: -53px;
  top: 220px;
}
.caseInfo .slick-arrow.slick-next{
  left: -53px;
  top: 250px;
  transform: rotateZ(180deg);
}
.caseInfo .slick-prev:before,
.caseInfo .slick-next:before {
  font-family: 'iconfont';
  content: '\e61d';
}

.marketing .marketingEnter {
  width: 243px;
  height: 57px;
  border-radius: 28px;
  background-color: #333333;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
}

.marketing .marketingEnter label {
  color: white;
  font-size: 20px;
}

.marketing .marketingEnter .slice {
  margin-left: 15px;
  margin-right: 9px;
  width: 41px;
  height: 41px;
}

.icon-slice:before {
  font-family: 'iconfont';
  content: '\e61d';
}

.marketing .slice {
  background: #49C5B6;
  color: #fff;
  transform: rotate(125deg);
}

.marketing .marketingEnter:hover .slice {
  animation: enterSpin 1 .25s linear;
  animation-fill-mode: forwards;
}

@keyframes enterSpin {
  from {
    transform: rotate(125deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.marketing .slick-swiper .slick-list {
  height: 565px!important;
  max-height: none!important;
}

.cooperation-item {
  width: 100%;
  height: 335px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  overflow: hidden;
}

.marketing .Union {
  position: absolute;
  z-index: -1;
  width: 67.71%;
  left: 0;
  top: -10%;
}

.marketing .Subtract {
  position: absolute;
  z-index: -1;
  width: 45%;
  right: -40px;
  top: -10%;
}

.marketing .contentTitle_by {
  z-index: 2;
}

.marketing .contact-box-flex {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marketing .contact-box-item {
  width: 300px;
}
