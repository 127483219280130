.bottom-company {
    margin-top: 40px;
}
.bottom-company .environment {
    margin-right: 25px;
    width: 474px;
}

.bottom-company .environment .box.on {
    /*background-color: white;*/
    border-radius: 26px;
}
.bottom-company .environment .box label {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    transition: .5s transform cubic-bezier(0.2, 0.0, 0.2, 1);
    display: inline-block;
}
.bottom-company .environment .box.on label {
    transform: translate(25px, 0);
}
.bottom-company .environment .box.on p {
    width: 100%;
    opacity: 1;
    position: static;
    transform: translate(25px, 0);
    transition: .5s transform cubic-bezier(0.2, 0, 0.2, 1), .2s opacity cubic-bezier(0.2, 0, 0.2, 1);
}
.bottom-company .environment .box p {
    margin: 8px 0 0;
    color: #a5a5a5;
    opacity: 0;
    position: absolute;
    transform: translate(0, 20px);
    width: 113.5%;
}
.bottom-company .environment .box {
    height: 132px;
    font-weight: 550;
    text-align: left;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
    box-sizing: border-box;
}
.bottom-company .environment .box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: 0;
    background: #fff;
}
.bottom-company .environment .box.on::before {
    width: 100%;
    transition: 0.5s width cubic-bezier(0.2, 0, 0.2, 1);
}
.bottom-company .environment .box:not(.on) + .box:not(.on) {
    border-top: 1px solid #a2a2a2;
}
.bottom-company img {
    width: 713px;
    height: 531px;
    border-radius: 18px;
}

.bottom-company.enjoy .environment {
    margin-left: 25px;
    margin-right: 0;
}

.bottom-company.enjoy .environment .box {
    height: 105px;
    font-weight: 550;
    text-align: left;
    width: 100%;
}
