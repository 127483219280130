@font-face {
  font-family: 'iconfont';
  src: url('https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/font/iconfont.ttf?t=1636099189466') format('truetype');
}

@font-face {
  font-family: 'DIN Alternate';
  src: url('https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/font/DIN-Alternate.otf?t=1636099189466') format('truetype');
}

@font-face {
  font-family: 'PingFang SC';
  src: url('https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/font/PingFang-SC.ttf?t=1636099189466') format('truetype');
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #F6F6F6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.themeBg {
  background: #F6F6F6;
}

/* header样式 */
.headerMenu {
  background: #333;
  width: 100%;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(30px);
}

.headerMenu.black {
  background: #333333;
  backdrop-filter: none;
}

.headerMenu_img {
  margin-left: 50px;
  width: 154px;
  height: 34px;
}

.headerMenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-right: 20px;
  font-weight: 600;
}

.headerMenu li {
  float: right;
  min-width: 120px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.headerMenu li span,
.headerMenu li a {
  display: inline-block;
  color: white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-style: normal;
  font-size: 16px;
  height: 72px;
  line-height: 72px;
}

.headerMenu li div a {
  font-weight: normal;
  background-color: #fff;
  height: 26px;
  line-height: 26px;
}


.headerMenu .radiusT {
  border-radius: 4px 4px 0 0;
  padding-top: 16px !important;
}

.headerMenu .radiusB {
  border-radius: 0 0 4px 4px;
  padding-bottom: 16px !important;

}

.headerMenu .submenu-hidden {
  display: none;
  position: absolute;
  min-width: 120px;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.headerMenu .submenu-show {
  display: block;
  position: absolute;
  min-width: 120px;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  font-weight: 600;
}

.headerMenu .bgW {
  background-color: #fff;
}

.headerMenu .submenu-show a {
  color: black;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
}

.headerMenu li a.submenuitem-hover {
  color: #3CCFC3;
}

.headerMenu .menu-hover {
  background-color: rgba(255,255,255, 0.1)

}


/* page*/
.contentTitle {
  text-align: center;
  line-height: 72px;
  /* padding-left: 210px; */
}

.contentTitle .contentTitle_b {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding: 0 30px;
  position: relative;
  cursor: pointer;
  color: #666;
}

.contentTitle .contentTitle_b.active {
  color: #333;
}

.contentTitle .contentTitle_b div {
  padding: 0 10px;
}

.underline {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: #3CCFC3;
  border-radius: 7px;
}

.contentTitle_by {
  position: relative;
  width: 100%;
  margin-top: 120px;
}

.contentTitle_by .contentTitle_byb {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  color: #333;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.contentTitle_byb .contentTitle_byt {
  font-weight: bold;
  font-size: 34px;
  margin-bottom: 50px;
  margin-top: 0;
  color: #333;
}

.contentTitle_by .contentTitle_num {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 20px;
  margin-top: 14px;
  width: 80%;
  margin-left: 10%;
}

.contentTitle_by .contentTitle_num div {
  width: 50%;
  height: 80px;
}

.contentTitle_by .contentTitle_num .contentTitle_shu {
  height: 24px;
  width: 1px;
  background: #D9D9D9;
}

.contentTitle_by .contentTitle_num .p1 {
  font-family: 'DIN Alternate';
  color: #3CCFC3;
  font-weight: 500;
  font-size: 30px;
  margin: 8px 0 4px 0;
}

.contentTitle_by .contentTitle_num .p2 {
  margin: 0;
  font-size: 14px;
  color: #333;
}


.contentTitle_byb .contentTitle_name {
  font-weight: 500;
  font-size: 24px;
  margin: 20px 0 4px 0;
}

.contentTitle_byb .contentTitle_by_ct {
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 16px;
  text-align: left;
}

.contentTitle_byb .contentTitle_by_cb {
  font-size: 16px;
  text-align: left;
  line-height: 24px;
}

/* 图片黑白 */
.imgGray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}