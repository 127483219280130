.advertising .t1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #333;
}

.advertising .contentTitle_byt_t {
    margin-bottom: -30px;
    margin-top: 0;
    position: relative;
    z-index: 10;
    color: #333;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
}

.advertising .contentTitle_byt_n {
    position: relative;
    top: -36px;
}

.advertising .Subtract {
    position: absolute;
    z-index: -1;
    width: 57%;
    right: 0;
    top: -44%;
}

.advertising .Union {
    position: absolute;
    z-index: -1;
    width: 67.71%;
    left: 0;
    top: -86%;
}

.advertising .content-tabs {
    min-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: -10px;
}

.advertising .content-tab-item {
    width: 300px;
}

.advertising .nav-head-img{
    width: 100%;
    position: relative;
}
.advertising .nav-head-img img{
    width: 100%;
    vertical-align: bottom;
}

.advertising .nav-head-text {
    width: 216px;
    height: 66px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) matrix(1, 0, -0.1, 1, 0, 0);
}
.advertising .nav-head-text img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.advertising .slick-swiper .slick-list {
    height: 555px!important;
    max-height: none!important;
}
