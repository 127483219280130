.cs-contaienr {
    width: 1200px;
    margin: 60px auto 0 auto;
}

.cs-contaienr .slick-list {
    width: calc( 100vw - (100vw - 1200px) / 2);
}

.cs-contaienr .slick-swiper .slick-track{
    padding-top: 0;
}

.cs-contaienr .slick-swiper .slick-next{
    right: -25px;
}

.cs-contaienr .slick-swiper .slick-prev{
    left: -25px;
}


.cs-contaienr .slick-swiper .slick-list{
    height: auto;
}

.cs-contaienr .slick-slider .slick-prev, .cs-contaienr .slick-slider .slick-next{
    background: #333;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 100;
}

.cs-contaienr .cs-box {
    position: relative;
    width: 952px;
    display: flex;
    justify-content: space-between;
}

.cs-contaienr .cs-box img {
    border-radius: 22px;
}


.cs-contaienr .cs-item img {
    width: 687px;
}

.cs-contaienr .cs-item .right {
    width: 240px;
    text-align: left;
}

.cs-contaienr .right h3 {
    font-family: DIN Alternate;
    font-style: normal;
    font-weight: bold;
    font-size: 76px;
    line-height: 88px;
    /* vi青色 */
    color: #49C5B6;
    margin-top: 20px;
    margin-bottom: 13px;
}

.cs-contaienr .right h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    /* vi青色 */
    color: #333333;
    margin-bottom: 19px;
}

.cs-contaienr .right p {
    line-height: 160%;
    /* or 26px */
    text-align: justify;
    letter-spacing: 0.01em;
    color: #333333;
    font-size: 16px;
}

.cs-contaienr .cs-number {
    margin: 15px 0 0 0;
    padding: 0;
    text-align: left;
}

.cs-contaienr .cs-number li {
    list-style: none;
    display: inline-block;
    font-family: DIN Alternate;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
}

.cs-contaienr .cs-number li+li {
    margin-left: 23px;
}