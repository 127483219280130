.pattern-close{
    box-sizing: border-box;
    border-left: 5px solid #6DC2B6;
    width: 274px;
    height: 236px;
    background: #FFFFFF;
    margin-right: 21px;
    animation: close 1s;
    cursor: pointer;
}
@keyframes close {
    from{
        width: 580px;
    }
    to{
        width: 274px;
    }
}
.pc-title{
    /* 20号字medium */
    font-family: PingFang SC;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;
    margin-left: 26px;
    margin-top: 26px;
    /* 黑/333333 */

    color: #333333;
}
.pattern-wrapper{
    height: 236px;
    margin-right: -21px;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

@keyframes open {
    from{
        width: 274px;
    }
    to{
        width: 580px;
    }
}
.pattern-open{
    margin-right: 21px;
    display: flex;
    animation: open 1s;
}
.pattern-open img{
    height: 100%;
    
}

.pattern-open .po-content{
    width: 424px;
    height: 236px;
    font-family: PingFang SC;
    font-style: normal;
    color: #FFFFFF;
    font-weight: 500;
}
.pattern-open .po-title{
    margin-top: 26px;
    margin-left: 34px;
    text-align: left;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;
    font-weight: 600;
    font-family: PingFang SC;
    font-style: normal;
}
.pattern-open .po-describe{
    margin:25px 26px 0 34px ;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */

    text-align: justify;
}

.shop-add {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto 0 auto;
}


.shop-add .marketingEnter {
    width: 210px;
    height: 57px;
    border-radius: 28px;
    background-color: #333333;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
}

.shop-add .marketingEnter label {
    color: white;
    font-size: 20px;
}

.shop-add .marketingEnter .slice {
    margin-left: 15px;
    margin-right: 9px;
    width: 41px;
    height: 41px;
}

.icon-slice:before {
    font-family: 'iconfont';
    content: '\e61d';
}

.shop-add .slice {
    background: #49C5B6;
    color: #fff;
    transform: rotate(125deg);
}

.shop-add .marketingEnter:hover .slice {
    animation: enterSpin 1 .25s linear;
    animation-fill-mode: forwards;
}

@keyframes enterSpin {
    from {
        transform: rotate(125deg);
    }
    to {
        transform: rotate(180deg);
    }
}
