.content {
    margin-top: 34px;
    white-space: pre-wrap;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #333333;
}

.data .number {
    font-family: 'DIN Alternate';
    margin-right: 0.1em;
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 65px;
    text-align: center;
    color: #49C5B6;
}

.data {
    margin-top: 34px;
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    width: 900px;
}

.data .unit {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 56px;
    /* identical to box height */
    text-align: center;
    color: #49C5B6;
}

.data .subTitle {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */
    text-align: center;
    color: #333333;
}

.tab-wrapper {
    margin-top: 34px;
    margin-bottom: 20px;
}

.card-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 34px;
    justify-content: space-evenly;
}


/*  */

.starResources-introduce .slick-swiper .slick-prev {
    left: -70px
}

.starResources-introduce .slick-swiper .slick-next {
    right: -70px;
}

.starResources-introduce .slick-swiper,
.starResources-introduce .slick-slide {
    padding: 10px!important;
}

.introduce-flex {
    width: calc(100% + 2px);
    background: #F6F6F6;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    border-radius: 20px;
}

.introduce-flex a {
    display: inline-block;
    text-decoration: none;
}

.introduce-flex-body {
    width: 100%;
    height: 386px;
    overflow: hidden;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
}

.introduce-flex-img {
    width: 100%;
    height: 280px;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
}

.introduce-flex-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.introduce-flex-content {
    padding: 15px;
}

.introduce-flex-content-name {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.introduce-flex-content-introduce {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    overflow: hidden;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.introduce-flex-content-img {
    width: 20px;
    height: 20px;
    margin-left: 4px;
}

.flexccc .video-react-big-play-button {
    display: none;
}

a:link {
    text-decoration:none;
}

.resources .nav-head-img{
    width: 100%;
    position: relative;
}
.resources .nav-head-img img{
    width: 100%;
    vertical-align: bottom;
}

.resources .nav-head-text {
    width: 216px;
    height: 66px;
}
.resources .nav-head-text img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.resources .nav-head-img div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) matrix(1, 0, -0.1, 1, 0, 0);
    font-family: HYRunYuan;
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 66px;
    /* identical to box height */
    color: #FFFFFF;
}

.resources .Subtract {
    position: absolute;
    z-index: -1;
    width: 45.65%;
    max-width: 1000px;
    right: 0;
    top: 20%;
}

.resources .Subtract.on {
    right: -10%;
    top: 50%;
    transition: all 4s ease-out;
}

/* .resources .Subtract {
    position: absolute;
    z-index: -1;
    width: 52.65%;
    max-width: 1000px;
    right: 0;
    top: 40%;
    transition: all 2s ease-out;
}

.resources .Subtract.on {
    right: -10%;
    top: 60%;
    transition: all 2s ease-out;
}
*/