.home .t1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 44px;
    margin-top: -10px;
    color: #333;
}

.home .Subtract {
    position: absolute;
    z-index: -1;
    width: 52.65%;
    max-width: 1000px;
    right: 0;
    top: -110%;
}

.home .Subtract.on {
    right: -10%;
    top: -120%;
    transition: all 2s ease-out;
}

.home .Union {
    position: absolute;
    z-index: -1;
    width: 80.21%;
    max-width: 1000px;
    left: 0;
    top: -30%;
}

.home .Union.on {
    left: -10%;
    top: -40%;
    transition: all 2s ease-out;
}

.home .flex {
    display: flex;
}

.home .imgHover {
    width: 33%;
    border-radius: 20px;
    max-height: 26vw;
    overflow: hidden;
    position: relative;
}
.home .imgHover-new:hover .imgGray,
.home .imgHover:hover .imgGray {
    cursor: pointer;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    filter: grayscale(0%);
    filter: rgba(0, 0, 0, 0);
}

.home .describe-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(66, 66, 66, 0.07) 66.15%, rgba(34, 34, 34, 0.2) 81.77%, rgba(0, 0, 0, 0.3) 100%);
    border-radius: 20px;
    left: 0;
    top: 0;
}

.home .describe {
    position: absolute;
    left: 24px;
    bottom: 24px;
}

.home .describe .describe-t {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 4px;
}

.home .describe .describe-c {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    text-align: left;
}

.home .react-video-play .video-react-has-started .video-react-control-bar {
    display: none;
}

.home .homeT1Cover {
    display: none;
    position: absolute;
    /*width: 100%;*/
    height: 100%;
    background: rgba(0, 0, 0, .8);
    border-radius: 20px;
    left: 5px;
    width: calc(100% - 10px);
}

.home .homeT1Cover-wrap:hover .homeT1Cover{
    display: block;
}

.home .slick-active.slick-center .homeT1Cover {
    display: block;
}

.home .slick-active .homeT1Cover .homeT1Cover-by {
    position: absolute;
    top: 50%;
    left: 4%;
    transform: translateY(-50%);
}

.home .slick-active .homeT1Cover .homeT1Cover-t {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 17px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 8px;
}


.home .slick-active .homeT1Cover .homeT1Cover-c {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 11px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 6px;
}

.home .video-react-big-play-button {
    display: none;
}

.home .homeT1tCover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    cursor: pointer;
}

.home .homeT1tCover .play {
    background: url("https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/home/polygon-icon.png");
    width: 80px;
    height: 80px;
    position: absolute;
    background-size: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home .slick-swiper .slick-prev {
    left: -4%;
}

.home .slick-swiper .slick-next {
    right: -4.6%;
}

@keyframes example {
    from {
        margin-left: 100%;
    }

    to {
        margin-left: -100%;
    }
}


.home .animationImg {
    position: absolute;
    top: 0;
    left: 0;
    animation-name: example;
    animation-iteration-count: infinite;
    animation-duration: 20s;
}

@keyframes example1 {
    from {
        margin-left: 100%;
    }

    to {
        margin-left: -100%;
    }
}


.home .animationImg1 {
    top: 0;
    left: 0;
    animation-name: example1;
    animation-iteration-count: infinite;
    animation-duration: 20s;
    animation-delay: 10s;
    margin-left: 100%;
}