.carousel {
  width: 100%;
  height: 22vw;
  position: relative;
  cursor: pointer;
  user-select: none
}

.carousel>div {
  color: #fff;
  width: 7.2vw;
  height: 22vw;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 10vw;
  font-family: sans-serif;
  font-size: 6vw;
  user-select: none
}

.carousel-item {
  overflow: hidden;
  position: relative;
}

.carousel-item img {
  margin-left: -50%;
  height: 100%;
  width: auto;
}