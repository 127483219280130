.about {
    height: 100%;
    background-color: #f6f6f6;
}

.about .our {
    margin-top: 40px;
}

.about .our img {
    border-radius: 20px;
}

.about .belisted {
    width: 603px;
    margin-top: 40px;
}

.about .history .slick-list {
    overflow: visible;
}

.about .history .slick-slide {
    opacity: 0;
    transition: opacity 0.4s ease;
}

.about .history .slick-slide.slick-active {
    opacity: 1;
}

.about .timeline {
    width: 1202px;
    height: 85px;
    position: relative;
    z-index: 3;
    margin-top: 65px;
}

.about .timeline .timeline-cord {
    position: absolute;
    bottom: 22px;
    border: 0;
    border-bottom: 3px solid #333333;
    width: 100%;
    margin-top: 0px;
    z-index: -1;
}
.about .timeline .timeline-item {
    position: relative;
    text-align: center;
    overflow: visible;
    cursor: pointer;
}

.about .timeline .timeline-item:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #333;
    bottom: 17px;
}

.about .timeline .slick-current .timeline-item label {
    color: #49c5b6;
}

.about .timeline .slick-current .timeline-item i {
    background-color: #49c5b6;
}
.about .timeline .timeline-item label {
    font-size: 22px;
    color: #333333;
    font-family: DIN Alternate;
}
.about .timeline .timeline-item i {
    font-style: normal;
    display: inline-block;
    width: 19px;
    height: 19px;
    border-radius: 19px;
    margin-top: 9px;
    font-size: 18px;
    background-color: #333333;
    border: 5px solid #f6f6f6;
}

.about .timeline .timeline-item.on label {
    color: #6dc2b6;
}
.about .timeline .timeline-item.on i {
    background-color: #6dc2b6;
}

.about .brief {
    font-size: 20px;
    color: #000;
}

.mt10 {
    margin-top: 10px;
}

.mt30 {
    margin-top: 30px;
}

.about .ourmission {
  width: 555px;
  height: 451px;
    padding-left: 40px;
    margin-left: -20px;
  background-size: 100% 100%;
    background-repeat: no-repeat;
  text-align: left;
    border-radius: 22px;
    z-index: -1;
}

.about .ourmission .ourmission-en {
  padding-left: 20px;
  font-family: DIN Alternate;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  background: #fff;
  margin-top: 80px;
  /* vi青色 */

  color: #49C5B6;
  position: relative;
}

.about .ourmission .ourmission-en:before {
  content: '';
  width: 3px;
  background: #49C5B6;
  position: absolute;
  left: 0;
  top: 50%;
  height: 30px;
  margin-top: -15px;
}
.about .ourmission .ourmission-ch {
  padding-left: 20px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  background: #fff;
  margin-top: 12px;
  /* 黑/333333 */

  color: #333333;
}

.about .nav-head-img{
    width: 100%;
    position: relative;
}
.about .nav-head-img img{
    width: 100%;
    vertical-align: bottom;
}

.about .nav-head-text {
    width: 216px;
    height: 66px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) matrix(1, 0, -0.1, 1, 0, 0);
}

.about .contentTitle_by {
   position: relative;
    z-index: 3;
}

.about .Union {
    position: absolute;
    z-index: -1;
    width: 67.71%;
    left: 0;
    top: -50%;
}

.about .Subtract {
    position: absolute;
    z-index: -1;
    width: 61%;
    right: -40px;
    top: 30%;
}

.about .our-mission-left {
    width: 495px;
    height: 450px;
    background-image: url("https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/about/our-bg.png");
    border-radius: 22px;
}