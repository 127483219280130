.title::after{
    content: '';
    display: block;
    text-align: center;
    width: 67px;
    height: 4px;
    background: #3CCFC3;
    border-radius: 12px; 
    transform: translateX(-50%);
    margin-left: 50%;
}
.title{
    text-align: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 48px;
    margin-top: 2em;
}