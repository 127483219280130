 html,
 body,
 #root {
   height: 100%;
 }
 .none {
   display: none;
 }
 .hand {
   cursor: pointer;
 }
 .posr {
   position: relative;
 }
 .posa {
   position: absolute;
 }
 .mga {
   margin: auto;
 }
 .fill {
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
 }
 .ft12 {
   font-size: 12px;
 }
 .ft14 {
   font-size: 14px;
 }
 .ft15 {
   font-size: 15px;
 }
 .ft16 {
   font-size: 16px;
 }
 .ft18 {
   font-size: 18px;
 }
 .ft20 {
   font-size: 20px;
 }
 .ft22 {
   font-size: 22px;
 }
 .ft24 {
   font-size: 24px;
 }
 .ft25 {
   font-size: 25px;
 }
 .ft28 {
   font-size: 28px;
 }
 .ft30 {
   font-size: 30px;
 }
 .ft33 {
   font-size: 33px;
 }
 .ft34 {
   font-size: 34px;
 }
 .ft36 {
   font-size: 36px;
 }
 .ft42 {
   font-size: 42px;
 }
 .ft48 {
   font-size: 48px;
 }
 .flex1 {
   flex: 1;
 }
 .flex2 {
   flex: 2;
 }
 .flexrb {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
 }
 .flexrbc {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
 }
 .flexrac {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
 }
 .flexrcs {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: flex-start;
 }
 .flexrcc {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
 }
 .flexrce {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: flex-end;
 }
 .flexrbs-n {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
   flex-wrap: wrap;
 }
 .flexrbs {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
 }
 .flexrec {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;
 }
 .flexrevc {
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
 }
 .flexrss {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-start;
   flex-wrap: wrap;
 }
 .flexrss-n {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-start;
   flex-wrap: nowrap;
 }
 .flexrsc {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
 }
 .flexrse {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-end;
 }
 .flexcac {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: center;
 }
 .flexcc {
   display: flex;
   flex-direction: column;
   justify-content: center;
 }
 .flexcb {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
 }
 .flexcbc {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
 }
 .flexcbe {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-end;
 }
 .flexcbs {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
 }
 .flexcee {
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: flex-end;
 }
 .flexcec {
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: center;
 }
 .flexccc {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }
 .flexccs {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
 }
 .flexcss {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
 }
 .flexcas {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: flex-start;
 }
 .flexcsc {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
 }
 .flexcevc {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
 }
 .wfull-noblock {
   width: 100%;
   margin: auto;
 }
 .wfull {
   width: 100%;
   margin: auto;
   display: block;
 }
 .tgl {
   text-align: left;
 }
 .tgc {
   text-align: center;
 }
 .tabc {
   display: 'table';
   margin: '0 auto';
 }
 .tgr {
   text-align: right;
 }
