.light-shop{
    width: 100%;
}
.light-shop .shop-describe{
    white-space: pre-wrap;
}
.light-shop .ls-content{
    position: relative;
    width: 100%;
    margin-top: 70px;
}
.light-shop .ls-content img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ls-content .first,
.ls-content .second,
.ls-content .forth,
.ls-content .fifth,
.ls-content .sixth,
.ls-content .zero
{
    text-align: center;
    display: block;
}
.ls-content .first div,
.ls-content .second div,
.ls-content .forth div,
.ls-content .fifth div,
.ls-content .zero div,
.ls-content .sixth div
{
    width: 252px;
    height: 252px;
    overflow: hidden;
}
.ls-content .third{
    text-align: center;
    display: block;
}
.ls-content .third div{
    width: 371px;
    height: 371px;
    overflow: hidden;
}
.ls-content>section{
    display: none;
    position: absolute;
    transform: translate(-50%, -50%);
}
.ls-content section div{
    
    border-radius: 20px;
}
.ls-content section span{
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */

    text-align: center;

    /* 黑/333333 */

    color: #333333;
    border-radius: 20px;
    margin-top: 22px;
    display: block;
}
.ls-operate{
    margin-top: 160px;
    display: flex;
    justify-content: center;
}
.ls-operate img:first-child{
    margin-right: 24px;
}
.ls-operate img{
    width: 40px;
    cursor: pointer;
}
.ls-operate img.disabled{
    filter: brightness(60%);
    cursor: unset;
    
}