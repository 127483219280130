.slick-swiper .slick-list {
    max-height: 570px;
    height: 32vw;
}

.slick-swiper .slick-track {
    padding-top: 60px;
}

.slick-swiper .slick-slide.slick-center {
    position: relative;
    z-index: 10;
}

.slick-swiper .slick-slide {
    position: relative;
    z-index: 5;
    padding: 6px 10px;
    box-sizing: border-box;
}

.slick-swiper .slick-slide > div {
    transition: all 0.2s ease;
}

.slick-swiper .slick-center > div {
    transform: scale(1.26);
}

/**/
.slick-swiper .flexImg {
    position: relative;
}

.slick-swiper .slick-center .sliderLeftImg {
    display: block;
    position: absolute;
    width: 100%;
    transform: scale(0.8);
    top: 0;
    left: -26.8%;
}

.slick-swiper .sliderLeftImg {
    display: none;
}

.slick-swiper .slick-center .sliderRightImg {
    display: block;
    position: absolute;
    width: 100%;
    transform: scale(0.8);
    top: 0;
    /*left: 25.6%;*/
    right: -25.5%;
}

.slick-swiper .slick-center .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
}

.slick-swiper .sliderRightImg {
    display: none;
}

.slick-swiper .flexImg .radius {
    border-radius: 20px;
    overflow: hidden;
}

.slick-swiper .cover-text-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: linear-gradient(
            180deg,
            rgba(196, 196, 196, 0) 0%,
            rgba(66, 66, 66, 0.07) 66.15%,
            rgba(34, 34, 34, 0.2) 81.77%,
            rgba(0, 0, 0, 0.3) 100%
    );
}

.slick-swiper .cover-text-wrap .cover-text {
    width: 100%;
    color: #fff;
    position: absolute;
    left: 20px;
    bottom: 25px;
    text-align: left;
    transform: scale(0.8);
    transform-origin: left bottom;
}

.slick-swiper .cover-text-wrap .cover-title {
    width: 300px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #fff;
    position: absolute;
    left: 20px;
    top: 15px;
    max-width: 505px;
    text-align: left;
    transform: scale(0.8);
    transform-origin: left bottom;
    font-size: 16px;
}

.slick-swiper .cover-text-wrap .cover-text .cover-text-title {
    font-family: PingFang SC;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
}
.slick-swiper .cover-text-wrap .cover-text .cover-text-desc{
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
}

.slick-swiper .cover-text-wrap .cover-text .cover-text-desc {
   font-size: 14px;
}

.slick-swiper .sliderImg {
    position: relative;
    z-index: 14;
}

.slick-swiper div {
    outline: none;
}

.slick-swiper-tab {
    width: max-content;
    height: 46px;
    background: #ffffff;
    border-radius: 56px;
    box-shadow: 0px 0px 11px 0 rgba(0, 0, 0, 0.15);
    margin: 0 auto;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.slick-swiper-title-active {
    width: 150px;
    height: 46px;
    border-radius: 56px;
    background: rgba(51, 51, 51, 1);
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .3s;
}

.slick-swiper-title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    width: 150px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 56px;
    cursor: pointer;
    color: #333;
    transition: color .3s;
}

.slick-swiper-title-click {
    color: #fff;
    position: relative;
    z-index: 999;
}

.slick-swiper .slick-prev,
.slick-swiper .slick-next {
    background: #333;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 100;
    opacity: 1;
    transition: none !important;
    font-weight: 600;
}

.slick-swiper .slick-prev:hover,
.slick-swiper .slick-next:hover {
    background: #49c5b6 !important;
}

.slick-swiper .slick-prev {
    left: 70px;
}

.slick-swiper .slick-next {
    right: 70px;
}

.slick-swiper .slick-prev:before {
    font-family: 'iconfont';
    content: '\e61d';
}

.slick-swiper .slick-next:before {
    font-family: 'iconfont';
    content: '\e61a';
}

.slick-swiper .slick-prev:before,
.slick-swiper .slick-next:before {
    font-weight: lighter;
    opacity: 1;
}

.slick-swiper:hover .slick-arrow {
    opacity: 1;
    transition: all 1s;
}

/* video */
.slick-swiper .slick-swiper-video {
    position: absolute;
    left: 7.6%;
    top: 43.4%;
    width: 47.4%;
    height: 44%;
}

.slick-swiper .slick-swiper-video video {
    width: 100%;
    height: 100%;
}

/* type == 'banner' */
.noScale .slick-swiper .slick-center > div {
    transform: scale(1);
}

.noScale .slick-swiper .slick-track {
    margin-top: 0;
    padding-top: 0;
}

.noScale .slick-swiper .slick-list {
    max-height: max-content;
    height: max-content;
}

.noScale .slick-swiper .slick-prev,
.noScale .slick-swiper .slick-next {
    top: 42%;
}

/*.slick-swiper .slick-active + .slick-cloned .sliderImg {*/
/*    visibility: hidden !important;*/
/*    opacity: 0;*/
/*}*/
.slick-swiper .slick-slide .sliderImg {
    transition: 0.2s all;
    visibility: hidden;
    opacity: 0;
}
.slick-swiper .slick-slide.slick-active .sliderImg,
.slick-swiper .slick-slide.slick-center .sliderImg {
    visibility: visible;
    opacity: 1;
}
