.introduce .content-wrapper {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 68px;
    position: relative;
}


/* .content-wrapper:last-child{
    position: relative;
} */

.img-qrcode {
    width: 129px;
    position: absolute;
    right: 50px;
    bottom: -250px;
    z-index: 999;
}

.img-qrcode img {
    width: 100%;
}

.content-wrapper .person-title {
    font-size: 34px;
    line-height: 48px;
    font-family: 'PingFang SC';
    font-weight: bold;
    /* identical to box height */
    color: #333333;
    display: flex;
    align-items: center;
}

.content-wrapper .bar {
    background: #6dc2b6;
    border-radius: 12px;
    height: 32px;
    width: 4px;
}

.person-title span {
    margin-left: 0.5em;
}

.introduce {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    color: #333333;
}

.introduce .introduce-wrapper {
    margin-top: 40px;
    display: flex;
}

.introduce .introduce-wrapper .main-img {
    width: 275px;
    height: 300px;
    border-radius: 20px;
    margin-right: 37px;
    position: relative;
    overflow: hidden;
}

.introduce .introduce-wrapper .main-img img {
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
  /*  position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);*/
}

.font-bold {
    font-weight: bold;
}

.introduce .introduce-wrapper .introduce-title {
    font-size: 24px;
    line-height: 34px;
    color: #333333;
    display: flex;
    margin-top: 20px;
}

.introduce-wrapper .introduce-title .title-tag {
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: #333333;
    margin-left: 2em;
    background: #e8e8e8;
    border-radius: 30px;
    align-self: center;
    padding: 0 1em;
}

.person-property {
    width: 100px;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
}

.person-describe.part1 {
    display: flex;
}

.person-describe.part1 img {
    width: 20px;
    border-radius: 50%;
}

.part1>div {
    align-self: center;
}

.part1 img {
    vertical-align: text-top;
}

.person-describe.part1 span {
    font-family: DIN Alternate;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    margin-left: 0.1em;
    text-align: center;
    color: #333333;
}

.person-describe.part1 div {
    border-radius: 30px;
    margin-right: 1em;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
}

.person-describe {
    font-size: 16px;
    line-height: 160%;
    /* or 26px */
    text-align: justify;
    white-space: pre-wrap;
    /* 黑/666666 */
    color: #666666;
}

.person-describe.part2 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    /* justify-content: flex-start;
    align-content: flex-start */
}

.person-describe .tag {
    line-height: 22px;
    border-radius: 30px;
    padding: 0 1em;
    margin-right: 1em;
    margin-bottom: 0.5em;
    color: #fff;
    font-family: PingFang SC;
    background: #333;
}

.person-describe .hot {
    width: 100%;
}


/* .part2 div {
    background: #333333;
    border-radius: 30px;
    padding: 0 1em;
    margin-right: 1em;
    height: 25px;
    line-height: 24px;
    color: #ffffff;
    font-family: PingFang SC;
}

.part2 {
    display: flex;
} */

.part3 .person-property {
    flex: none;
}

.part4 .person-property {
    flex: none;
}

.photoList {
    display: flex;
    margin-top: 40px;
    margin-right: -20px;
}

.photoList .img-wrapper {
    width: 286px;
    height: 382.25px;
    border-radius: 20px;
    filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.05));
    margin-right: 20px;
    position: relative;
    overflow: hidden;
}

.photoList .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.introduce .Subtract {
    position: absolute;
    width: 2000px;
    left: -5%;
    top:10%;
    z-index: 9;
}

.introduce .Subtract.on {
    left: -15%;
    top: 10%;
    transition: all 2s ease-out;
}

.introduce .Union {
    position: absolute;
    z-index: 0;
    width: 40.71%;
    left: 0;
    top: 15%;
}