.footer {
    width: 100%;
    background-size: 100% 100%;
    color: white;
    /*margin-top: 40px;*/
    background-repeat: no-repeat;
    position: relative;
    background-color: #333;
}
.footer-circle {
    content: '';
    width: 100%;
    height: 130px;
    background: #F6F6F6;
    border-radius: 0 0 0 150px;
}

.flexrss-bottom {
    display: flex;
    justify-content: flex-end;
    padding-right: 5%;
    padding-top: 100px;
    position: relative;
    z-index: 9;
    background: #333;
}

.footer>div:nth-child(1){

}
.footer>div:nth-child(1)>div:nth-child(2){
    margin-left: 90px;
}
.footer .joinUs div:nth-child(1){
    font-size: 20px;
    padding-bottom: 30px;
}
.footer .joinUs a{
    font-size: 16px;
    line-height: 54px;
    color: white;
    text-decoration: none;
    display: block;
}

.footer .contactUs div:nth-child(1){
    font-size: 20px;
    padding-bottom: 30px;
    letter-spacing:1px;
}
.footer .contactUs div:not(:nth-child(1)){
    font-size: 16px;
    line-height: 54px;
}
.footer .copyright {
    font-size: 16px;
    letter-spacing:1px;
    padding-top: 36px;
    padding-left: 68px;
    padding-bottom: 60px;
}

.footer .logo {
   position: absolute;
   left:98px;
   top:200px;
   width: 281px;
   height: 64px;
    z-index: 10;
}

.footer .contactUs .flex {
    display: flex;
}