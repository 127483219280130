    .joinUs .contentTitle_by {
        margin-top: 110px;
    }

    .joinUs .contentTitle_by .imgBox {
        width: 1450px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .imgBox div {
        font-size: 0;
    }

    .imgBox .box .img2 {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .imgBox .box .img1 img,
    .imgBox .box .img2 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px!important;
    }

    .imgBox .box0 {
        width: 139px;
        height: 183px;
        margin-top: 110px;
        z-index: 1;
    }

    .imgBox .box1 {
        width: 266px;
        height: 350px;
        margin-left: -30px;
    }

    .imgBox .box2 {
        width: 197px;
        height: 250px;
        margin-top: 320px;
        margin-left: -150px;
        z-index: 1;
    }

    .imgBox .box3 {
        width: 244px;
        height: 310px;
        margin-top: 130px;
        margin-left: 30px;
    }

    .imgBox .box4 {
        width: 164px;
        height: 208px;
        margin-top: 50px;
        margin-left: -30px;
        z-index: 1;
    }

    .imgBox .box5 {
        width: 192px;
        height: 244px;
        z-index: 1;
        margin-top: 330px;
        margin-left: -140px;
    }

    .imgBox .box6 {
        width: 265px;
        height: 336px;
        margin-top: 130px;
    }

    .imgBox .box7 {
        width: 167px;
        height: 214px;
        z-index: 1;
        margin-top: 410px;
        margin-left: -30px;
    }

    .imgBox .box8 {
        width: 241px;
        height: 316px;
        margin-left: -125px;
    }

    .imgBox .box9 {
        width: 159px;
        height: 219px;
        z-index: 1;
        margin-left: -116px;
        margin-top: 270px;
    }

    .box {
        overflow: hidden;
        position: relative;
        border-radius: 20px;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
    }

    .box>div {
        flex-shrink: 0;
    }

    .box .img2 {
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(1);
    }

    .box .img1 {
        width: 0;
        transform: scale(1.3);
    }

    .active .box .img1 {
        transform: scale(1);
        overflow: hidden;
        transition-property: width, transform;
        transition-duration: 2s;
        transition-timing-function: cubic-bezier(0.45, 0.2, 0.1, 1);
    }

    .active .box .img2 {
        transform: scale(1.3);
        transition: transform 2s cubic-bezier(0.45, 0.2, 0.1, 1);
    }

    .active .box0 .img1 {
        width: 139px;
        height: 183px;
    }

    .active .box1 .img1 {
        width: 266px;
        height: 350px;
    }

    .active .box2 .img1 {
        width: 197px;
        height: 250px;
    }

    .active .box3 .img1 {
        width: 244px;
        height: 310px;
    }

    .active .box4 .img1 {
        width: 164px;
        height: 208px;
    }

    .active .box5 .img1 {
        width: 192px;
        height: 244px;
    }

    .active .box6 .img1 {
        width: 265px;
        height: 336px;
    }

    .active .box7 .img1 {
        width: 167px;
        height: 214px;
    }

    .active .box8 .img1 {
        width: 241px;
        height: 316px;
    }

    .active .box9 .img1 {
        width: 159px;
        height: 219px;
    }

    .active .box0 .img1 img {
        width: 139px;
        height: 183px;
    }

    .active .box1 .img1 img {
        width: 266px;
        height: 350px;
    }

    .active .box2 .img1 img {
        width: 197px;
        height: 250px;
    }

    .active .box3 .img1 img {
        width: 244px;
        height: 310px;
    }

    .active .box4 .img1 img {
        width: 164px;
        height: 208px;
    }

    .active .box5 .img1 img {
        width: 192px;
        height: 244px;
    }

    .active .box6 .img1 img {
        width: 265px;
        height: 336px;
    }

    .active .box7 .img1 img {
        width: 167px;
        height: 214px;
    }

    .active .box8 .img1 img {
        width: 241px;
        height: 316px;
    }

    .active .box9 .img1 img {
        width: 159px;
        height: 219px;
    }

    .joinUs .t1 {
        font-size: 20px;
        line-height: 34px;
        color: #4C4C4C;
        margin-bottom: 55px;
        display: flex;
        justify-content: center;
    }

    .joinUs .Subtract {
        position: absolute;
        z-index: -1;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        /*top: -40%;*/
        top: 0;
    }

    .joinUs .bg-wrap {
        padding-bottom: 130px;
        margin-bottom: -130px;
        overflow: hidden;
        border-radius: 0 0 0 140px;
        z-index: 1;
        position: relative;
        width: 100%;
    }

    /*@media screen and (min-width:1920px) {*/
    /*    .joinUs .contentTitle_by .Subtract {*/
    /*        top: -40%;*/
    /*    }*/
    /*}*/

    /*@media screen and (min-width:1680px) and (max-width: 1919px) {*/
    /*    .joinUs .contentTitle_by .Subtract {*/
    /*        top: -30%;*/
    /*    }*/
    /*}*/

    /*@media screen and (min-width:1660px) and (max-width: 1679px) {*/
    /*    .joinUs .contentTitle_by .Subtract {*/
    /*        top: -28%;*/
    /*    }*/
    /*}*/

    /*@media screen and (min-width:1646px) and (max-width: 1659px) {*/
    /*    .joinUs .contentTitle_by .Subtract {*/
    /*        top: -26%;*/
    /*    }*/
    /*}*/

    /*@media screen and (min-width:1588px) and (max-width:1645px) {*/
    /*    .joinUs .contentTitle_by .Subtract {*/
    /*        top: -20%;*/
    /*    }*/
    /*}*/

    /*@media screen and (max-width:1587px) {*/
    /*    !*.joinUs .contentTitle_by .Subtract {*!*/
    /*    !*    display: none;*!*/
    /*    !*}*!*/
    /*}*/