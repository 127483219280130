/*右侧悬浮菜单*/
.qrCode {
    width: 65px;
    height: 70px;
    position: fixed;
    top: 68%;
    right: 10px;
    background: #3CCFC3;
    background-size: 40px 40px !important;
    transform: translateY(-50%);
    border-radius: 8px;
    z-index: 999;
}
.img_btn{
    width: 100%;
    height: 100%;
    cursor: pointer;
    font-size: 12px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img_btn .img_btn_text {
    font-weight: bold;
    margin-top: 3px;
}

.img_btn img {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.img_code{
    width: 100px;
    height: 120px;
    position: absolute;
    left: -104px;
    top: 0;
}