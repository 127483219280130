
.grow-container{
    width: 1160px;
    margin: 40px auto 6px auto;
}

.grow-container .slick-list {
    width: calc( 100vw - (100vw - 1160px) / 2);
}

.grow-container .slick-swiper .slick-track{
    padding-top: 0;
}

.grow-container .slick-swiper .slick-next{
    right: -20px;
    top:150px;
}

.grow-container .slick-swiper .slick-prev{
    left: -20px;
    top:150px;
}


.grow-container .slick-swiper .slick-list{
    height: auto;
}

.grow-container .slick-slider .slick-prev, .cs-contaienr .slick-slider .slick-next{
    background: #333;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 100;
}
.grow-container .slick-slide +.slick-slide{
  margin-left: 41px;
}
  .grow-container .awardsItem {
    text-align: left;
    overflow: hidden;
    background-color: transparent;
  }

  .grow-container .awardsItem label{
    padding: 6px 10px 7px 10px;
    background-color: #49C5B6;
    font-size: 14px;
    color: white;
    border-radius: 20px;
      font-family: DIN Alternate;
      font-style: normal;
      font-weight: bold;
  }
  .grow-container .awardsItem p {
    line-height: 36px;
    display: block;
    text-align: left;
    font-size: 20px;
      color: #333;
   }
   .grow-container .awardsItem .img {
    overflow: hidden;
    border-radius: 12px;
    width: 100%;
    height: 391px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    background-color: #6dc2b6;
  }
  .grow-container .awardsItem .img img{
    height: 100%;
  }

  .grow-container .grow-list-box {
      width: 350px;
      height: 283px;
      line-height: 1.8;
      padding: 30px;
      box-sizing: border-box;
      background: #FFFFFF;
      border: 1px solid rgba(216, 216, 216, 0.4);
      border-radius: 12px;
      transition: background .3s;
  }

.grow-container .grow-list-box:hover {
    background: #49C5B6;
}

.grow-container .grow-list-box:hover .grow-list-header-title {
    color: #fff;
}

.grow-container .grow-list-box:hover .grow-list-content {
    color: #fff;
}

  .grow-container .grow-list-header {
      display: flex;
      align-items: center;
  }

  .grow-container .grow-list-header-index {
      width: 48px;
      height: 48px;
      font-family: 'Brice';
      line-height: 48px;
      text-align: center;
      background: #E4FFFC;
      border-radius: 10px;
      color: #49C5B6;
      font-size: 24px;
      font-weight: bold;
  }

  .grow-container .grow-list-header-title {
      color: #333333;
      font-size: 24px;
      font-weight: 600;
      margin-left: 20px;
  }

  .grow-container .grow-list-content {
      margin-top: 30px;
      color: #999999;
      font-size: 16px;
      text-align: left;
  }