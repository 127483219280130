.tab div.tab-choose {
    color: #fff;
}

.tab-active {
    width: 25%;
    height: 100%;
    border-radius: 57px;
    background: #333333;
    position: absolute;
    left: 0;
    transition: left 300ms;
}

.tab div {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    width: 126px;
    color: #333333;
    line-height: 46px;
    cursor: pointer;
}

.tab-item {
    position: relative;
    transition: color 600ms;
    z-index: 99;
}

.tab {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);
    border-radius: 57px;
    text-align: center;
    height: 46px;
    width: fit-content;
    margin-left: 50%;
    transform: translateX(-50%);
    position: relative;
}