.react-video-play .video-react .video-react-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 3em;
    line-height: 3em;
    border-radius: 50%;
}

.react-video-play .video-react .video-react-big-play-button:before {
    font-size: 2.6em;
}