.about .teamInfo{
    width: 1212px;
    margin-top: 40px;
    position: relative;
  }

  .about .teamInfoJoinus{
    text-align: right;
    padding-top: 35px;
    font-size: 20px;
    width: 1212px;
  }
  .about .teamInfoJoinus a{
    color: #030303;
    text-decoration: none;
  }
  .about .teamInfoJoinus a img{
    margin-left: 12px;
  }
  .about .teamInfoJoinus a:hover img{
    animation: joinusSpin 1 .25s linear;
    animation-fill-mode: forwards;
  }
  @keyframes joinusSpin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(45deg);
    }
  }

  .about .teamInfoItem{
    width: 389px;
    overflow: hidden;
    background-color: white;
    border-radius: 26px;
  }

  .aboutJoin{
    position: absolute;
    right: 0;
    bottom: -58px;
    text-decoration: none;
    font-weight:600;
    font-size: 20px;
    line-height: 28px;
    color:#333333;
  }

  .aboutJoin img{
    margin-left: 12px;
    transition: all 0.2s linear;
    transform: rotate(0);
  }

  .aboutJoin:hover img{
    transform: rotate(45deg);

  }

  .about .teamInfoItem .label{
    font-weight: 600;
    padding-top: 22px;
    padding-left: 20px;
    color: #333;
  }

  .about .teamInfoItem p{
    padding: 17px 20px;
    margin-top: 0;
    /*text-align: left;*/
    color: #666;
    font-size: 14px;
    line-height: 160%;
    text-align: justify;
    letter-spacing: 0.01em;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
   }

  .about .teamInfoItem .img {
    overflow: hidden;
    border-radius: 26px;
    width: 389px;
    margin: 0;
    padding: 0;
  }

  .about .teamInfoItem .img img{
      width: 100%;
      height: 100%;
      filter: grayscale(1);
      transition: all 0.5s linear;
    vertical-align: top;
  }

  .about .teamInfoItem:hover .img img{
      filter: grayscale(0);
  }
