.starClass .t1 {
    font-size: 20px;
    line-height: 40px;
    color: #575757;
    margin-bottom: 6px;
}

.starClass .img_code {
    width: 100px;
    height: 120px;
    position: absolute;
    left: -60px;
    top: -60px;
}

.starClass .contentTitle_byb .contentTitle_by_ct{
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 150%;
    color: #333333;
}

.starClass .contentTitle_byb .contentTitle_by_cb {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: #333333;
  }