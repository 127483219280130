.koc-container{
    width: 100%;
    margin-top: 40px;
    filter: blur(15px);
    background: #F6F6F6;
}

.koc-container .koc-row{
    width: 100%;
    position: relative;
    height: 120px;
}

.koc-container .koc-row+.koc-row{
    margin-top: 10px;
}

.koc-container .koc-box{
    display: inline-block;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s linear;
    -moz-user-select:none;/**ff*/
    -webkit-user-select:none;/**chrome、safari、360极速*/
}

.koc-container .koc-box img {
    width: 100px;
}

.koc-container .koc-row.offset{
    transform: translateX(78px);
}

.koc-container .koc-box.hide{
    opacity: 0;
}
