.coBranding{
    background-color: #f6f6f6;
}
.coBranding .coBrandingTitle:nth-child(3){
    margin-top: 30px;
}
.coBranding .coBrandingTitle{
    font-family: PingFang SC;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: #333333;
}
.coBranding .coBrandingTitle + .coBrandingTitle{
    margin-top: 20px;
}

.coBranding .coBrandingMore {
    background-color: #333333;
    color: white;
    font-size: 14px;
    border-radius: 20px;
    width: 96px;
    height: 39px;   
    line-height: 39px;
    margin-top: 39px;
    cursor: pointer;
}

.coBranding .coBrandingMore:hover {
    background: #49C5B6;
}

.coBrandingFlow {
    background-color: transparent;
    width: 74.35%;
    height: auto;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.coBrandingFlow .flowline {
    width: 332px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;   
    padding: 0 5px;     
}

.coBrandingFlow .flowline .flowItem{
    width: 100%;
    overflow: hidden; 
    background-color: white;
    border-radius:24px;
    margin-bottom: 40px;
    box-shadow:0px 2px 6px #ebebeb;
}

.coBrandingFlow .flowline .flowItem img{ 
    width: 100%;
    margin: 0;
    padding: 0;
 }

 .coBrandingFlow .flowline .flowItem label{
     font-size: 20px;
     font-weight: bold;
     padding-left: 21px;
     padding-top: 10px;
     display: block;
     text-align: left;
     color: #333333;
 }

 .coBrandingFlow .flowline .flowItem p{
    padding:0 21px;
    text-align: left;
}

 .coBranding .contentTitle_by {
     z-index: 1;
 }

.coBranding .Subtract {
    position: absolute;
    z-index: -1;
    width: 48%;
    right: -40px;
    top: 38%;
}