@font-face {
  font-family: 'iconfont';
  src: url('https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/font/iconfont.ttf?t=1636099189466') format('truetype');
}

@font-face {
  font-family: 'DIN Alternate';
  src: url('https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/font/DIN-Alternate.otf?t=1636099189466') format('truetype');
}

@font-face {
  font-family: 'PingFang SC';
  src: url('https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/font/PingFang-SC.ttf?t=1636099189466') format('truetype');
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #F6F6F6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.themeBg {
  background: #F6F6F6;
}

/* header样式 */
.headerMenu {
  background: #333;
  width: 100%;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
}

.headerMenu.black {
  background: #333333;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}

.headerMenu_img {
  margin-left: 50px;
  width: 154px;
  height: 34px;
}

.headerMenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-right: 20px;
  font-weight: 600;
}

.headerMenu li {
  float: right;
  min-width: 120px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.headerMenu li span,
.headerMenu li a {
  display: inline-block;
  color: white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-style: normal;
  font-size: 16px;
  height: 72px;
  line-height: 72px;
}

.headerMenu li div a {
  font-weight: normal;
  background-color: #fff;
  height: 26px;
  line-height: 26px;
}


.headerMenu .radiusT {
  border-radius: 4px 4px 0 0;
  padding-top: 16px !important;
}

.headerMenu .radiusB {
  border-radius: 0 0 4px 4px;
  padding-bottom: 16px !important;

}

.headerMenu .submenu-hidden {
  display: none;
  position: absolute;
  min-width: 120px;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.headerMenu .submenu-show {
  display: block;
  position: absolute;
  min-width: 120px;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  font-weight: 600;
}

.headerMenu .bgW {
  background-color: #fff;
}

.headerMenu .submenu-show a {
  color: black;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
}

.headerMenu li a.submenuitem-hover {
  color: #3CCFC3;
}

.headerMenu .menu-hover {
  background-color: rgba(255,255,255, 0.1)

}


/* page*/
.contentTitle {
  text-align: center;
  line-height: 72px;
  /* padding-left: 210px; */
}

.contentTitle .contentTitle_b {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding: 0 30px;
  position: relative;
  cursor: pointer;
  color: #666;
}

.contentTitle .contentTitle_b.active {
  color: #333;
}

.contentTitle .contentTitle_b div {
  padding: 0 10px;
}

.underline {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: #3CCFC3;
  border-radius: 7px;
}

.contentTitle_by {
  position: relative;
  width: 100%;
  margin-top: 120px;
}

.contentTitle_by .contentTitle_byb {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  color: #333;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.contentTitle_byb .contentTitle_byt {
  font-weight: bold;
  font-size: 34px;
  margin-bottom: 50px;
  margin-top: 0;
  color: #333;
}

.contentTitle_by .contentTitle_num {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 20px;
  margin-top: 14px;
  width: 80%;
  margin-left: 10%;
}

.contentTitle_by .contentTitle_num div {
  width: 50%;
  height: 80px;
}

.contentTitle_by .contentTitle_num .contentTitle_shu {
  height: 24px;
  width: 1px;
  background: #D9D9D9;
}

.contentTitle_by .contentTitle_num .p1 {
  font-family: 'DIN Alternate';
  color: #3CCFC3;
  font-weight: 500;
  font-size: 30px;
  margin: 8px 0 4px 0;
}

.contentTitle_by .contentTitle_num .p2 {
  margin: 0;
  font-size: 14px;
  color: #333;
}


.contentTitle_byb .contentTitle_name {
  font-weight: 500;
  font-size: 24px;
  margin: 20px 0 4px 0;
}

.contentTitle_byb .contentTitle_by_ct {
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 16px;
  text-align: left;
}

.contentTitle_byb .contentTitle_by_cb {
  font-size: 16px;
  text-align: left;
  line-height: 24px;
}

/* 图片黑白 */
.imgGray {
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}
.backtotop {
    position: fixed;
    bottom: 72px;
    right: 68px;
    animation: fadeIn 700ms ease-in-out 1s both;
    cursor: pointer;
    width: 60px;
    height: 60px;
    background: #49C5B6;
    border-radius: 100%;
    text-align: center;
    line-height: 60px;
    z-index: 99;
}
.backtotop:not(.hideColor):hover {
    background: #333333;
}
.backtotop:before{
    font-family: 'iconfont';
    content: '\E61A';
    transform: rotate(-90deg);
    color: #fff;
    display: block;
    font-size: 30px;
}
.footer {
    width: 100%;
    background-size: 100% 100%;
    color: white;
    /*margin-top: 40px;*/
    background-repeat: no-repeat;
    position: relative;
    background-color: #333;
}
.footer-circle {
    content: '';
    width: 100%;
    height: 130px;
    background: #F6F6F6;
    border-radius: 0 0 0 150px;
}

.flexrss-bottom {
    display: flex;
    justify-content: flex-end;
    padding-right: 5%;
    padding-top: 100px;
    position: relative;
    z-index: 9;
    background: #333;
}

.footer>div:nth-child(1){

}
.footer>div:nth-child(1)>div:nth-child(2){
    margin-left: 90px;
}
.footer .joinUs div:nth-child(1){
    font-size: 20px;
    padding-bottom: 30px;
}
.footer .joinUs a{
    font-size: 16px;
    line-height: 54px;
    color: white;
    text-decoration: none;
    display: block;
}

.footer .contactUs div:nth-child(1){
    font-size: 20px;
    padding-bottom: 30px;
    letter-spacing:1px;
}
.footer .contactUs div:not(:nth-child(1)){
    font-size: 16px;
    line-height: 54px;
}
.footer .copyright {
    font-size: 16px;
    letter-spacing:1px;
    padding-top: 36px;
    padding-left: 68px;
    padding-bottom: 60px;
}

.footer .logo {
   position: absolute;
   left:98px;
   top:200px;
   width: 281px;
   height: 64px;
    z-index: 10;
}

.footer .contactUs .flex {
    display: flex;
}
.contentTitleFollowme{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
}
.contentTitleNotFollowme{
    width: 100%;
}
.bottom-company {
    margin-top: 40px;
}
.bottom-company .environment {
    margin-right: 25px;
    width: 474px;
}

.bottom-company .environment .box.on {
    /*background-color: white;*/
    border-radius: 26px;
}
.bottom-company .environment .box label {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    transition: .5s transform cubic-bezier(0.2, 0.0, 0.2, 1);
    display: inline-block;
}
.bottom-company .environment .box.on label {
    transform: translate(25px, 0);
}
.bottom-company .environment .box.on p {
    width: 100%;
    opacity: 1;
    position: static;
    transform: translate(25px, 0);
    transition: .5s transform cubic-bezier(0.2, 0, 0.2, 1), .2s opacity cubic-bezier(0.2, 0, 0.2, 1);
}
.bottom-company .environment .box p {
    margin: 8px 0 0;
    color: #a5a5a5;
    opacity: 0;
    position: absolute;
    transform: translate(0, 20px);
    width: 113.5%;
}
.bottom-company .environment .box {
    height: 132px;
    font-weight: 550;
    text-align: left;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
    box-sizing: border-box;
}
.bottom-company .environment .box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: 0;
    background: #fff;
}
.bottom-company .environment .box.on::before {
    width: 100%;
    transition: 0.5s width cubic-bezier(0.2, 0, 0.2, 1);
}
.bottom-company .environment .box:not(.on) + .box:not(.on) {
    border-top: 1px solid #a2a2a2;
}
.bottom-company img {
    width: 713px;
    height: 531px;
    border-radius: 18px;
}

.bottom-company.enjoy .environment {
    margin-left: 25px;
    margin-right: 0;
}

.bottom-company.enjoy .environment .box {
    height: 105px;
    font-weight: 550;
    text-align: left;
    width: 100%;
}

.about .teamInfo{
    width: 1212px;
    margin-top: 40px;
    position: relative;
  }

  .about .teamInfoJoinus{
    text-align: right;
    padding-top: 35px;
    font-size: 20px;
    width: 1212px;
  }
  .about .teamInfoJoinus a{
    color: #030303;
    text-decoration: none;
  }
  .about .teamInfoJoinus a img{
    margin-left: 12px;
  }
  .about .teamInfoJoinus a:hover img{
    animation: joinusSpin 1 .25s linear;
    animation-fill-mode: forwards;
  }
  @keyframes joinusSpin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(45deg);
    }
  }

  .about .teamInfoItem{
    width: 389px;
    overflow: hidden;
    background-color: white;
    border-radius: 26px;
  }

  .aboutJoin{
    position: absolute;
    right: 0;
    bottom: -58px;
    text-decoration: none;
    font-weight:600;
    font-size: 20px;
    line-height: 28px;
    color:#333333;
  }

  .aboutJoin img{
    margin-left: 12px;
    transition: all 0.2s linear;
    transform: rotate(0);
  }

  .aboutJoin:hover img{
    transform: rotate(45deg);

  }

  .about .teamInfoItem .label{
    font-weight: 600;
    padding-top: 22px;
    padding-left: 20px;
    color: #333;
  }

  .about .teamInfoItem p{
    padding: 17px 20px;
    margin-top: 0;
    /*text-align: left;*/
    color: #666;
    font-size: 14px;
    line-height: 160%;
    text-align: justify;
    letter-spacing: 0.01em;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
   }

  .about .teamInfoItem .img {
    overflow: hidden;
    border-radius: 26px;
    width: 389px;
    margin: 0;
    padding: 0;
  }

  .about .teamInfoItem .img img{
      width: 100%;
      height: 100%;
      filter: grayscale(1);
      transition: all 0.5s linear;
    vertical-align: top;
  }

  .about .teamInfoItem:hover .img img{
      filter: grayscale(0);
  }


.awards-container{
    width: 1160px;
    margin: 40px auto 6px auto;
}

.awards-container .slick-list {
    width: calc( 100vw - (100vw - 1160px) / 2);
}

.awards-container .slick-swiper .slick-track{
    padding-top: 0;
}

.awards-container .slick-swiper .slick-next{
    right: -20px;
    top:200px;
}

.awards-container .slick-swiper .slick-prev{
    left: -20px;
    top:200px;
}


.awards-container .slick-swiper .slick-list{
    height: auto;
}

.awards-container .slick-slider .slick-prev, .cs-contaienr .slick-slider .slick-next{
    background: #333;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 100;
}
.awards-container .slick-slide +.slick-slide{
  margin-left: 41px;
}
  .awards-container .awardsItem {
    text-align: left;
    overflow: hidden;
    background-color: transparent;
  }

  .awards-container .awardsItem label{
    padding: 6px 10px 7px 10px;
    background-color: #49C5B6;
    font-size: 14px;
    color: white;
    border-radius: 20px;
      font-family: DIN Alternate;
      font-style: normal;
      font-weight: bold;
  }
  .awards-container .awardsItem p {
    line-height: 36px;
    display: block;
    text-align: left;
    font-size: 20px;
      color: #333;
   }
   .awards-container .awardsItem .img {
    overflow: hidden;
    border-radius: 12px;
    width: 100%;
    height: 391px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    background-color: #6dc2b6;
  }
  .awards-container .awardsItem .img img{
      width: 100%;
    height: 100%;
  }

.grow-container{
    width: 1160px;
    margin: 40px auto 6px auto;
}

.grow-container .slick-list {
    width: calc( 100vw - (100vw - 1160px) / 2);
}

.grow-container .slick-swiper .slick-track{
    padding-top: 0;
}

.grow-container .slick-swiper .slick-next{
    right: -20px;
    top:150px;
}

.grow-container .slick-swiper .slick-prev{
    left: -20px;
    top:150px;
}


.grow-container .slick-swiper .slick-list{
    height: auto;
}

.grow-container .slick-slider .slick-prev, .cs-contaienr .slick-slider .slick-next{
    background: #333;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 100;
}
.grow-container .slick-slide +.slick-slide{
  margin-left: 41px;
}
  .grow-container .awardsItem {
    text-align: left;
    overflow: hidden;
    background-color: transparent;
  }

  .grow-container .awardsItem label{
    padding: 6px 10px 7px 10px;
    background-color: #49C5B6;
    font-size: 14px;
    color: white;
    border-radius: 20px;
      font-family: DIN Alternate;
      font-style: normal;
      font-weight: bold;
  }
  .grow-container .awardsItem p {
    line-height: 36px;
    display: block;
    text-align: left;
    font-size: 20px;
      color: #333;
   }
   .grow-container .awardsItem .img {
    overflow: hidden;
    border-radius: 12px;
    width: 100%;
    height: 391px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    background-color: #6dc2b6;
  }
  .grow-container .awardsItem .img img{
    height: 100%;
  }

  .grow-container .grow-list-box {
      width: 350px;
      height: 283px;
      line-height: 1.8;
      padding: 30px;
      box-sizing: border-box;
      background: #FFFFFF;
      border: 1px solid rgba(216, 216, 216, 0.4);
      border-radius: 12px;
      transition: background .3s;
  }

.grow-container .grow-list-box:hover {
    background: #49C5B6;
}

.grow-container .grow-list-box:hover .grow-list-header-title {
    color: #fff;
}

.grow-container .grow-list-box:hover .grow-list-content {
    color: #fff;
}

  .grow-container .grow-list-header {
      display: flex;
      align-items: center;
  }

  .grow-container .grow-list-header-index {
      width: 48px;
      height: 48px;
      font-family: 'Brice';
      line-height: 48px;
      text-align: center;
      background: #E4FFFC;
      border-radius: 10px;
      color: #49C5B6;
      font-size: 24px;
      font-weight: bold;
  }

  .grow-container .grow-list-header-title {
      color: #333333;
      font-size: 24px;
      font-weight: 600;
      margin-left: 20px;
  }

  .grow-container .grow-list-content {
      margin-top: 30px;
      color: #999999;
      font-size: 16px;
      text-align: left;
  }
.about {
    height: 100%;
    background-color: #f6f6f6;
}

.about .our {
    margin-top: 40px;
}

.about .our img {
    border-radius: 20px;
}

.about .belisted {
    width: 603px;
    margin-top: 40px;
}

.about .history .slick-list {
    overflow: visible;
}

.about .history .slick-slide {
    opacity: 0;
    transition: opacity 0.4s ease;
}

.about .history .slick-slide.slick-active {
    opacity: 1;
}

.about .timeline {
    width: 1202px;
    height: 85px;
    position: relative;
    z-index: 3;
    margin-top: 65px;
}

.about .timeline .timeline-cord {
    position: absolute;
    bottom: 22px;
    border: 0;
    border-bottom: 3px solid #333333;
    width: 100%;
    margin-top: 0px;
    z-index: -1;
}
.about .timeline .timeline-item {
    position: relative;
    text-align: center;
    overflow: visible;
    cursor: pointer;
}

.about .timeline .timeline-item:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #333;
    bottom: 17px;
}

.about .timeline .slick-current .timeline-item label {
    color: #49c5b6;
}

.about .timeline .slick-current .timeline-item i {
    background-color: #49c5b6;
}
.about .timeline .timeline-item label {
    font-size: 22px;
    color: #333333;
    font-family: DIN Alternate;
}
.about .timeline .timeline-item i {
    font-style: normal;
    display: inline-block;
    width: 19px;
    height: 19px;
    border-radius: 19px;
    margin-top: 9px;
    font-size: 18px;
    background-color: #333333;
    border: 5px solid #f6f6f6;
}

.about .timeline .timeline-item.on label {
    color: #6dc2b6;
}
.about .timeline .timeline-item.on i {
    background-color: #6dc2b6;
}

.about .brief {
    font-size: 20px;
    color: #000;
}

.mt10 {
    margin-top: 10px;
}

.mt30 {
    margin-top: 30px;
}

.about .ourmission {
  width: 555px;
  height: 451px;
    padding-left: 40px;
    margin-left: -20px;
  background-size: 100% 100%;
    background-repeat: no-repeat;
  text-align: left;
    border-radius: 22px;
    z-index: -1;
}

.about .ourmission .ourmission-en {
  padding-left: 20px;
  font-family: DIN Alternate;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  background: #fff;
  margin-top: 80px;
  /* vi青色 */

  color: #49C5B6;
  position: relative;
}

.about .ourmission .ourmission-en:before {
  content: '';
  width: 3px;
  background: #49C5B6;
  position: absolute;
  left: 0;
  top: 50%;
  height: 30px;
  margin-top: -15px;
}
.about .ourmission .ourmission-ch {
  padding-left: 20px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  background: #fff;
  margin-top: 12px;
  /* 黑/333333 */

  color: #333333;
}

.about .nav-head-img{
    width: 100%;
    position: relative;
}
.about .nav-head-img img{
    width: 100%;
    vertical-align: bottom;
}

.about .nav-head-text {
    width: 216px;
    height: 66px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) matrix(1, 0, -0.1, 1, 0, 0);
}

.about .contentTitle_by {
   position: relative;
    z-index: 3;
}

.about .Union {
    position: absolute;
    z-index: -1;
    width: 67.71%;
    left: 0;
    top: -50%;
}

.about .Subtract {
    position: absolute;
    z-index: -1;
    width: 61%;
    right: -40px;
    top: 30%;
}

.about .our-mission-left {
    width: 495px;
    height: 450px;
    background-image: url("https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/about/our-bg.png");
    border-radius: 22px;
}
.slick-swiper .slick-list {
    max-height: 570px;
    height: 32vw;
}

.slick-swiper .slick-track {
    padding-top: 60px;
}

.slick-swiper .slick-slide.slick-center {
    position: relative;
    z-index: 10;
}

.slick-swiper .slick-slide {
    position: relative;
    z-index: 5;
    padding: 6px 10px;
    box-sizing: border-box;
}

.slick-swiper .slick-slide > div {
    transition: all 0.2s ease;
}

.slick-swiper .slick-center > div {
    transform: scale(1.26);
}

/**/
.slick-swiper .flexImg {
    position: relative;
}

.slick-swiper .slick-center .sliderLeftImg {
    display: block;
    position: absolute;
    width: 100%;
    transform: scale(0.8);
    top: 0;
    left: -26.8%;
}

.slick-swiper .sliderLeftImg {
    display: none;
}

.slick-swiper .slick-center .sliderRightImg {
    display: block;
    position: absolute;
    width: 100%;
    transform: scale(0.8);
    top: 0;
    /*left: 25.6%;*/
    right: -25.5%;
}

.slick-swiper .slick-center .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
}

.slick-swiper .sliderRightImg {
    display: none;
}

.slick-swiper .flexImg .radius {
    border-radius: 20px;
    overflow: hidden;
}

.slick-swiper .cover-text-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: linear-gradient(
            180deg,
            rgba(196, 196, 196, 0) 0%,
            rgba(66, 66, 66, 0.07) 66.15%,
            rgba(34, 34, 34, 0.2) 81.77%,
            rgba(0, 0, 0, 0.3) 100%
    );
}

.slick-swiper .cover-text-wrap .cover-text {
    width: 100%;
    color: #fff;
    position: absolute;
    left: 20px;
    bottom: 25px;
    text-align: left;
    transform: scale(0.8);
    transform-origin: left bottom;
}

.slick-swiper .cover-text-wrap .cover-title {
    width: 300px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #fff;
    position: absolute;
    left: 20px;
    top: 15px;
    max-width: 505px;
    text-align: left;
    transform: scale(0.8);
    transform-origin: left bottom;
    font-size: 16px;
}

.slick-swiper .cover-text-wrap .cover-text .cover-text-title {
    font-family: PingFang SC;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
}
.slick-swiper .cover-text-wrap .cover-text .cover-text-desc{
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
}

.slick-swiper .cover-text-wrap .cover-text .cover-text-desc {
   font-size: 14px;
}

.slick-swiper .sliderImg {
    position: relative;
    z-index: 14;
}

.slick-swiper div {
    outline: none;
}

.slick-swiper-tab {
    width: max-content;
    height: 46px;
    background: #ffffff;
    border-radius: 56px;
    box-shadow: 0px 0px 11px 0 rgba(0, 0, 0, 0.15);
    margin: 0 auto;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.slick-swiper-title-active {
    width: 150px;
    height: 46px;
    border-radius: 56px;
    background: rgba(51, 51, 51, 1);
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .3s;
}

.slick-swiper-title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    width: 150px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 56px;
    cursor: pointer;
    color: #333;
    transition: color .3s;
}

.slick-swiper-title-click {
    color: #fff;
    position: relative;
    z-index: 999;
}

.slick-swiper .slick-prev,
.slick-swiper .slick-next {
    background: #333;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 100;
    opacity: 1;
    transition: none !important;
    font-weight: 600;
}

.slick-swiper .slick-prev:hover,
.slick-swiper .slick-next:hover {
    background: #49c5b6 !important;
}

.slick-swiper .slick-prev {
    left: 70px;
}

.slick-swiper .slick-next {
    right: 70px;
}

.slick-swiper .slick-prev:before {
    font-family: 'iconfont';
    content: '\E61D';
}

.slick-swiper .slick-next:before {
    font-family: 'iconfont';
    content: '\E61A';
}

.slick-swiper .slick-prev:before,
.slick-swiper .slick-next:before {
    font-weight: lighter;
    opacity: 1;
}

.slick-swiper:hover .slick-arrow {
    opacity: 1;
    transition: all 1s;
}

/* video */
.slick-swiper .slick-swiper-video {
    position: absolute;
    left: 7.6%;
    top: 43.4%;
    width: 47.4%;
    height: 44%;
}

.slick-swiper .slick-swiper-video video {
    width: 100%;
    height: 100%;
}

/* type == 'banner' */
.noScale .slick-swiper .slick-center > div {
    transform: scale(1);
}

.noScale .slick-swiper .slick-track {
    margin-top: 0;
    padding-top: 0;
}

.noScale .slick-swiper .slick-list {
    max-height: max-content;
    height: max-content;
}

.noScale .slick-swiper .slick-prev,
.noScale .slick-swiper .slick-next {
    top: 42%;
}

/*.slick-swiper .slick-active + .slick-cloned .sliderImg {*/
/*    visibility: hidden !important;*/
/*    opacity: 0;*/
/*}*/
.slick-swiper .slick-slide .sliderImg {
    transition: 0.2s all;
    visibility: hidden;
    opacity: 0;
}
.slick-swiper .slick-slide.slick-active .sliderImg,
.slick-swiper .slick-slide.slick-center .sliderImg {
    visibility: visible;
    opacity: 1;
}

.react-video-play .video-react .video-react-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 3em;
    line-height: 3em;
    border-radius: 50%;
}

.react-video-play .video-react .video-react-big-play-button:before {
    font-size: 2.6em;
}
.carousel {
  width: 100%;
  height: 22vw;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none
}

.carousel>div {
  color: #fff;
  width: 7.2vw;
  height: 22vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 10vw;
  font-family: sans-serif;
  font-size: 6vw;
  -webkit-user-select: none;
          user-select: none
}

.carousel-item {
  overflow: hidden;
  position: relative;
}

.carousel-item img {
  margin-left: -50%;
  height: 100%;
  width: auto;
}
.home .t1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 44px;
    margin-top: -10px;
    color: #333;
}

.home .Subtract {
    position: absolute;
    z-index: -1;
    width: 52.65%;
    max-width: 1000px;
    right: 0;
    top: -110%;
}

.home .Subtract.on {
    right: -10%;
    top: -120%;
    transition: all 2s ease-out;
}

.home .Union {
    position: absolute;
    z-index: -1;
    width: 80.21%;
    max-width: 1000px;
    left: 0;
    top: -30%;
}

.home .Union.on {
    left: -10%;
    top: -40%;
    transition: all 2s ease-out;
}

.home .flex {
    display: flex;
}

.home .imgHover {
    width: 33%;
    border-radius: 20px;
    max-height: 26vw;
    overflow: hidden;
    position: relative;
}
.home .imgHover-new:hover .imgGray,
.home .imgHover:hover .imgGray {
    cursor: pointer;
    -moz-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    filter: grayscale(0%);
    filter: rgba(0, 0, 0, 0);
}

.home .describe-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(66, 66, 66, 0.07) 66.15%, rgba(34, 34, 34, 0.2) 81.77%, rgba(0, 0, 0, 0.3) 100%);
    border-radius: 20px;
    left: 0;
    top: 0;
}

.home .describe {
    position: absolute;
    left: 24px;
    bottom: 24px;
}

.home .describe .describe-t {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
    text-align: left;
    margin-bottom: 4px;
}

.home .describe .describe-c {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    text-align: left;
}

.home .react-video-play .video-react-has-started .video-react-control-bar {
    display: none;
}

.home .homeT1Cover {
    display: none;
    position: absolute;
    /*width: 100%;*/
    height: 100%;
    background: rgba(0, 0, 0, .8);
    border-radius: 20px;
    left: 5px;
    width: calc(100% - 10px);
}

.home .homeT1Cover-wrap:hover .homeT1Cover{
    display: block;
}

.home .slick-active.slick-center .homeT1Cover {
    display: block;
}

.home .slick-active .homeT1Cover .homeT1Cover-by {
    position: absolute;
    top: 50%;
    left: 4%;
    transform: translateY(-50%);
}

.home .slick-active .homeT1Cover .homeT1Cover-t {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 17px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 8px;
}


.home .slick-active .homeT1Cover .homeT1Cover-c {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 11px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 6px;
}

.home .video-react-big-play-button {
    display: none;
}

.home .homeT1tCover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    cursor: pointer;
}

.home .homeT1tCover .play {
    background: url("https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/home/polygon-icon.png");
    width: 80px;
    height: 80px;
    position: absolute;
    background-size: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home .slick-swiper .slick-prev {
    left: -4%;
}

.home .slick-swiper .slick-next {
    right: -4.6%;
}

@keyframes example {
    from {
        margin-left: 100%;
    }

    to {
        margin-left: -100%;
    }
}


.home .animationImg {
    position: absolute;
    top: 0;
    left: 0;
    animation-name: example;
    animation-iteration-count: infinite;
    animation-duration: 20s;
}

@keyframes example1 {
    from {
        margin-left: 100%;
    }

    to {
        margin-left: -100%;
    }
}


.home .animationImg1 {
    top: 0;
    left: 0;
    animation-name: example1;
    animation-iteration-count: infinite;
    animation-duration: 20s;
    animation-delay: 10s;
    margin-left: 100%;
}
.title::after{
    content: '';
    display: block;
    text-align: center;
    width: 67px;
    height: 4px;
    background: #3CCFC3;
    border-radius: 12px; 
    transform: translateX(-50%);
    margin-left: 50%;
}
.title{
    text-align: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 48px;
    margin-top: 2em;
}
/*右侧悬浮菜单*/
.qrCode {
    width: 65px;
    height: 70px;
    position: fixed;
    top: 68%;
    right: 10px;
    background: #3CCFC3;
    background-size: 40px 40px !important;
    transform: translateY(-50%);
    border-radius: 8px;
    z-index: 999;
}
.img_btn{
    width: 100%;
    height: 100%;
    cursor: pointer;
    font-size: 12px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img_btn .img_btn_text {
    font-weight: bold;
    margin-top: 3px;
}

.img_btn img {
    width: 25px;
    height: 25px;
    object-fit: cover;
}

.img_code{
    width: 100px;
    height: 120px;
    position: absolute;
    left: -104px;
    top: 0;
}
.starClass .t1 {
    font-size: 20px;
    line-height: 40px;
    color: #575757;
    margin-bottom: 6px;
}

.starClass .img_code {
    width: 100px;
    height: 120px;
    position: absolute;
    left: -60px;
    top: -60px;
}

.starClass .contentTitle_byb .contentTitle_by_ct{
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 150%;
    color: #333333;
}

.starClass .contentTitle_byb .contentTitle_by_cb {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: #333333;
  }
.advertising .t1 {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #333;
}

.advertising .contentTitle_byt_t {
    margin-bottom: -30px;
    margin-top: 0;
    position: relative;
    z-index: 10;
    color: #333;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
}

.advertising .contentTitle_byt_n {
    position: relative;
    top: -36px;
}

.advertising .Subtract {
    position: absolute;
    z-index: -1;
    width: 57%;
    right: 0;
    top: -44%;
}

.advertising .Union {
    position: absolute;
    z-index: -1;
    width: 67.71%;
    left: 0;
    top: -86%;
}

.advertising .content-tabs {
    min-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: -10px;
}

.advertising .content-tab-item {
    width: 300px;
}

.advertising .nav-head-img{
    width: 100%;
    position: relative;
}
.advertising .nav-head-img img{
    width: 100%;
    vertical-align: bottom;
}

.advertising .nav-head-text {
    width: 216px;
    height: 66px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) matrix(1, 0, -0.1, 1, 0, 0);
}
.advertising .nav-head-text img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.advertising .slick-swiper .slick-list {
    height: 555px!important;
    max-height: none!important;
}

.pattern-close{
    box-sizing: border-box;
    border-left: 5px solid #6DC2B6;
    width: 274px;
    height: 236px;
    background: #FFFFFF;
    margin-right: 21px;
    animation: close 1s;
    cursor: pointer;
}
@keyframes close {
    from{
        width: 580px;
    }
    to{
        width: 274px;
    }
}
.pc-title{
    /* 20号字medium */
    font-family: PingFang SC;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;
    margin-left: 26px;
    margin-top: 26px;
    /* 黑/333333 */

    color: #333333;
}
.pattern-wrapper{
    height: 236px;
    margin-right: -21px;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

@keyframes open {
    from{
        width: 274px;
    }
    to{
        width: 580px;
    }
}
.pattern-open{
    margin-right: 21px;
    display: flex;
    animation: open 1s;
}
.pattern-open img{
    height: 100%;
    
}

.pattern-open .po-content{
    width: 424px;
    height: 236px;
    font-family: PingFang SC;
    font-style: normal;
    color: #FFFFFF;
    font-weight: 500;
}
.pattern-open .po-title{
    margin-top: 26px;
    margin-left: 34px;
    text-align: left;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;
    font-weight: 600;
    font-family: PingFang SC;
    font-style: normal;
}
.pattern-open .po-describe{
    margin:25px 26px 0 34px ;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */

    text-align: justify;
}

.shop-add {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto 0 auto;
}


.shop-add .marketingEnter {
    width: 210px;
    height: 57px;
    border-radius: 28px;
    background-color: #333333;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
}

.shop-add .marketingEnter label {
    color: white;
    font-size: 20px;
}

.shop-add .marketingEnter .slice {
    margin-left: 15px;
    margin-right: 9px;
    width: 41px;
    height: 41px;
}

.icon-slice:before {
    font-family: 'iconfont';
    content: '\E61D';
}

.shop-add .slice {
    background: #49C5B6;
    color: #fff;
    transform: rotate(125deg);
}

.shop-add .marketingEnter:hover .slice {
    animation: enterSpin 1 .25s linear;
    animation-fill-mode: forwards;
}

@keyframes enterSpin {
    from {
        transform: rotate(125deg);
    }
    to {
        transform: rotate(180deg);
    }
}

.light-shop{
    width: 100%;
}
.light-shop .shop-describe{
    white-space: pre-wrap;
}
.light-shop .ls-content{
    position: relative;
    width: 100%;
    margin-top: 70px;
}
.light-shop .ls-content img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ls-content .first,
.ls-content .second,
.ls-content .forth,
.ls-content .fifth,
.ls-content .sixth,
.ls-content .zero
{
    text-align: center;
    display: block;
}
.ls-content .first div,
.ls-content .second div,
.ls-content .forth div,
.ls-content .fifth div,
.ls-content .zero div,
.ls-content .sixth div
{
    width: 252px;
    height: 252px;
    overflow: hidden;
}
.ls-content .third{
    text-align: center;
    display: block;
}
.ls-content .third div{
    width: 371px;
    height: 371px;
    overflow: hidden;
}
.ls-content>section{
    display: none;
    position: absolute;
    transform: translate(-50%, -50%);
}
.ls-content section div{
    
    border-radius: 20px;
}
.ls-content section span{
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */

    text-align: center;

    /* 黑/333333 */

    color: #333333;
    border-radius: 20px;
    margin-top: 22px;
    display: block;
}
.ls-operate{
    margin-top: 160px;
    display: flex;
    justify-content: center;
}
.ls-operate img:first-child{
    margin-right: 24px;
}
.ls-operate img{
    width: 40px;
    cursor: pointer;
}
.ls-operate img.disabled{
    filter: brightness(60%);
    cursor: unset;
    
}
.flow-carousel{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}
.flow-carousel .slick-list {
    padding: 0 3px;
}
.flow-carousel .slick-slider.slick-initialized{
    width: 1264px;

}
.flow-block{
    box-sizing: border-box;
}
.flow-carousel .flow-wrapper > div{
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    display: flex;
}
.flow-carousel .slick-prev,
.flow-carousel .slick-next {
    background: #333;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 100;
    opacity: 1;
}
.flow-carousel .slick-prev:hover ,
.flow-carousel .slick-next:hover {
    background: #49C5B6;
}
.flow-carousel .slick-arrow.slick-prev{
    left: -53px;
}
.flow-carousel .slick-arrow.slick-next{
    right: -53px;
}
.flow-carousel .slick-prev:before {
    font-family: 'iconfont';
    content: '\E61D';
}
.fc-arrow-wrapper{
    width: 55px;
    align-self: center;
    display: flex;
    justify-content: center;

}
.fc-arrow-wrapper .img{

    height: 13.78px;
}
.flow-carousel .slick-next:before {
    font-family: 'iconfont';
    content: '\E61A';
}
.flow-block{
    position: relative;
    width: 261px;
    height: 237px;

    background: #FFFFFF;
    /* 卡片投影 */

    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 25px 23px;
}
.flow-block .fc-title{
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */

    letter-spacing: 0.01em;

    /* 黑/333333 */

    color: #333333;
}
.flow-block .fc-describe{
    font-size: 18px;
    line-height: 26px;
    text-align: justify;
    letter-spacing: 0.02em;
    margin-top: 14px;
    /* 黑/999999 */

    color: #999999;
}
.flow-block .fc-index{
    width: 70px;
    height: 47px;
    position: absolute;
    font-size: 50px;
    right: 23px;
    bottom: 25px;
}

.flow-block .fc-index img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.online .nav-head-img{
    width: 100%;
    position: relative;
}
.online .nav-head-img > img{
    width: 100%;    
    vertical-align: bottom;
}
.online .nav-head-text {
    width: 216px;
    height: 66px;
}
.online .nav-head-text img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.online{
    box-sizing: border-box;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    color: #333333;
}
.online .nav-head-img div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) matrix(1, 0, -0.1, 1, 0, 0);
    font-family: HYRunYuan;
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 66px;
    /* identical to box height */
    color: #FFFFFF;
}
.online .ruhnn-nav{
    padding-left: 10%;
}
.online-plan{
    width: 80%;
    margin: 0 10%;
    white-space: pre-wrap;
}
.online-plan .plan-describe{
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.04em;
    margin-top: 34px;
    /* 黑/333333 */

    color: #333333;
}
.case-img-wrapper{
    margin-top: 34px;
    display: flex;
    justify-content: center;
}

.case-carousel{
    width: 1210px;
    position: relative;
    height: 570px;
}
.case-carousel .left-button,.case-carousel .right-button{
    z-index: 0;
    position: absolute;
    top: 50%;
    width: 40px;
    transform: translateY(-50%);
    cursor: pointer;
}
.case-carousel .left-button{
    left: 52px;
}
.case-carousel .right-button{
    right: 52px;
}
@keyframes left-click-top{
    from {
        left: 0px;
        width: 712px;
        top: 65px;
        height: 440px;
        /* alpha: 0.5; */
    }
    to{
        left: 145px;
        width: 920px;
        top: 0px;
        height: 570px;
        /* alpha: 1; */
    }
}
@keyframes left-click-left{
    from {
        left: 145px;
        width: 504px;
        top: 97px;
        height: 376px;
        /* alpha: 1 */
    }
    to{
        left: 0px;
        width: 712px;
        top: 65px;
        height: 440px;
        /* alpha: 0.5 */
    }
}
@keyframes left-click-right{
    from {
        right: 145px;
        width: 920px;
        top: 0px;
        height: 570px;
    }
    to{
        right: 0px;
        width: 712px;
        top: 65px;
        height: 440px;
        
    }
}
@keyframes right-click-right{
    from {
        right: 145px;
        width: 504px;
        top: 97px;
        height: 376px;
        alpha: 1
    }
    to{
        right: 0px;
        width: 712px;
        top: 65px;
        height: 440px;
        alpha: 0.5
    }
}
@keyframes right-click-left{
    from {
        left: 145px;
        width: 920px;
        top: 0px;
        height: 570px;
    }
    to{
        left: 0px;
        width: 712px;
        top: 65px;
        height: 440px;
    }
}
@keyframes right-click-top {
    from {
        left: 498px;
        width: 712px;
        top: 65px;
        height: 440px;
    }
    to{
        left: 145px;
        width: 920px;
        top: 0px;
        height: 570px;
    }
}

.clearfix::after{
    clear: both;
    content: "";
    display: block;
}
.case-carousel .carousel-img{
    display: none;
    position: relative;
}
.case-carousel .carousel-img img{
    width: 100%;
    border-radius: 20px;
}
.case-carousel .carousel-img.top{
    position: absolute;
    display: block;
    top: 0;
    left: 145px;
    width: 920px;
    height: 570px;
    z-index: -2;
    animation: leftMove 1s ease-out;
    animation-iteration-count:1;
}
.case-carousel .carousel-img.right-click-top{
    animation: right-click-top 1s ease-out;
    animation-iteration-count:1;

}
.case-carousel .carousel-img.left-click-top{
    animation: left-click-top 1s ease-out;
    animation-iteration-count:1;

}
.case-carousel .carousel-img.left{
    position: absolute;
    display: block;
    top: 65px;
    left: 0;
    width: 712px;
    height: 440px;
    z-index: -3;
}
.case-carousel .carousel-img.right-click-left{
    animation: right-click-left 1s ease-out;
    animation-iteration-count:1;
}
.case-carousel .carousel-img.left-click-left{
    animation: left-click-left 1s ease-out;
    animation-iteration-count:1;
}
.case-carousel .carousel-img.left .mask,.carousel-img.right .mask{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    background: rgba(255,255,255,0.5);
}
.case-carousel .carousel-img.right{
    position: absolute;
    display: block;
    top: 65px;
    right: 0;
    width: 712px;
    height: 440px;
    z-index: -3;
    animation: topMove 1s ease-out;
    animation-iteration-count:1;
}
.case-carousel .carousel-img.right-click-right{
    animation: right-click-right 1s ease-out;
    animation-iteration-count:1;
}
.case-carousel .carousel-img.left-click-right{
    animation: left-click-right 1s ease-out;
    animation-iteration-count:1;
}
.case-carousel .carousel-img.right{

}
.online-plan .tags{
    text-align: left;
    display: flex;
    justify-content: space-between;
}
.online-plan .tags .online-tag .online-title{
    font-family: DIN Alternate;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    /* identical to box height */
    letter-spacing: -0.005em;
    text-transform: capitalize;

    /* vi青色 */
    margin-top: 34px;

    color: #49C5B6;
}

.online-plan .tags .online-tag .online-name{
    /* 24号字 */

    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    margin-top: 24px;

    /* 黑/333333 */

    color: #333333;
}
.online-plan .tags .online-tag .online-describe{
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    letter-spacing: 0.01em;

    /* 黑/999999 */

    color: #999999;
    margin-top: 14px;

}

.light-shop .shop-describe{
    
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    /* or 36px */

    text-align: center;

    /* 黑/333333 */

    color: #333333;
    margin-top: 34px;
}

.icon-slice {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #333;
    color: #fff;
    font-size: 18px;
    border-radius: 100%;
    cursor: pointer;
}

.icon-slice:hover {
    background: #49C5B6;
}

.icon-slice:before {
    font-family: 'iconfont';
    content: '\E61D';
}

.icon-slice.right {
    transform: rotateZ(180deg);
}

.ml15 {
    margin-left: 15px;
}

.font-family-sc {
    font-family: 'PingFang SC';

}

.brand-list {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.brand-section {
    margin-bottom: 20px;
    padding: 0 10px;
    box-sizing: content-box;
}

.brand-section img {
    width: 165px;
    height: 72px;
    object-fit: contain;
}

.online .Union {
    position: absolute;
    z-index: -1;
    width: 67.71%;
    left: 0;
    top: -10%;
}

.online .Subtract {
    position: absolute;
    z-index: -1;
    width: 61%;
    right: -40px;
    top: -90%;
}

/*
.brand-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    justify-content: center;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

.brand-item {
    width: 15%;
    margin-bottom: 20px;
    padding: 0 3px;
    box-sizing: content-box;
}

.brand-list img {
    width: 185px;
    height: 92px;
    object-fit: contain;
}*/

    .joinUs .contentTitle_by {
        margin-top: 110px;
    }

    .joinUs .contentTitle_by .imgBox {
        width: 1450px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .imgBox div {
        font-size: 0;
    }

    .imgBox .box .img2 {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .imgBox .box .img1 img,
    .imgBox .box .img2 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px!important;
    }

    .imgBox .box0 {
        width: 139px;
        height: 183px;
        margin-top: 110px;
        z-index: 1;
    }

    .imgBox .box1 {
        width: 266px;
        height: 350px;
        margin-left: -30px;
    }

    .imgBox .box2 {
        width: 197px;
        height: 250px;
        margin-top: 320px;
        margin-left: -150px;
        z-index: 1;
    }

    .imgBox .box3 {
        width: 244px;
        height: 310px;
        margin-top: 130px;
        margin-left: 30px;
    }

    .imgBox .box4 {
        width: 164px;
        height: 208px;
        margin-top: 50px;
        margin-left: -30px;
        z-index: 1;
    }

    .imgBox .box5 {
        width: 192px;
        height: 244px;
        z-index: 1;
        margin-top: 330px;
        margin-left: -140px;
    }

    .imgBox .box6 {
        width: 265px;
        height: 336px;
        margin-top: 130px;
    }

    .imgBox .box7 {
        width: 167px;
        height: 214px;
        z-index: 1;
        margin-top: 410px;
        margin-left: -30px;
    }

    .imgBox .box8 {
        width: 241px;
        height: 316px;
        margin-left: -125px;
    }

    .imgBox .box9 {
        width: 159px;
        height: 219px;
        z-index: 1;
        margin-left: -116px;
        margin-top: 270px;
    }

    .box {
        overflow: hidden;
        position: relative;
        border-radius: 20px;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
    }

    .box>div {
        flex-shrink: 0;
    }

    .box .img2 {
        position: absolute;
        top: 0;
        left: 0;
        transform: scale(1);
    }

    .box .img1 {
        width: 0;
        transform: scale(1.3);
    }

    .active .box .img1 {
        transform: scale(1);
        overflow: hidden;
        transition-property: width, transform;
        transition-duration: 2s;
        transition-timing-function: cubic-bezier(0.45, 0.2, 0.1, 1);
    }

    .active .box .img2 {
        transform: scale(1.3);
        transition: transform 2s cubic-bezier(0.45, 0.2, 0.1, 1);
    }

    .active .box0 .img1 {
        width: 139px;
        height: 183px;
    }

    .active .box1 .img1 {
        width: 266px;
        height: 350px;
    }

    .active .box2 .img1 {
        width: 197px;
        height: 250px;
    }

    .active .box3 .img1 {
        width: 244px;
        height: 310px;
    }

    .active .box4 .img1 {
        width: 164px;
        height: 208px;
    }

    .active .box5 .img1 {
        width: 192px;
        height: 244px;
    }

    .active .box6 .img1 {
        width: 265px;
        height: 336px;
    }

    .active .box7 .img1 {
        width: 167px;
        height: 214px;
    }

    .active .box8 .img1 {
        width: 241px;
        height: 316px;
    }

    .active .box9 .img1 {
        width: 159px;
        height: 219px;
    }

    .active .box0 .img1 img {
        width: 139px;
        height: 183px;
    }

    .active .box1 .img1 img {
        width: 266px;
        height: 350px;
    }

    .active .box2 .img1 img {
        width: 197px;
        height: 250px;
    }

    .active .box3 .img1 img {
        width: 244px;
        height: 310px;
    }

    .active .box4 .img1 img {
        width: 164px;
        height: 208px;
    }

    .active .box5 .img1 img {
        width: 192px;
        height: 244px;
    }

    .active .box6 .img1 img {
        width: 265px;
        height: 336px;
    }

    .active .box7 .img1 img {
        width: 167px;
        height: 214px;
    }

    .active .box8 .img1 img {
        width: 241px;
        height: 316px;
    }

    .active .box9 .img1 img {
        width: 159px;
        height: 219px;
    }

    .joinUs .t1 {
        font-size: 20px;
        line-height: 34px;
        color: #4C4C4C;
        margin-bottom: 55px;
        display: flex;
        justify-content: center;
    }

    .joinUs .Subtract {
        position: absolute;
        z-index: -1;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        /*top: -40%;*/
        top: 0;
    }

    .joinUs .bg-wrap {
        padding-bottom: 130px;
        margin-bottom: -130px;
        overflow: hidden;
        border-radius: 0 0 0 140px;
        z-index: 1;
        position: relative;
        width: 100%;
    }

    /*@media screen and (min-width:1920px) {*/
    /*    .joinUs .contentTitle_by .Subtract {*/
    /*        top: -40%;*/
    /*    }*/
    /*}*/

    /*@media screen and (min-width:1680px) and (max-width: 1919px) {*/
    /*    .joinUs .contentTitle_by .Subtract {*/
    /*        top: -30%;*/
    /*    }*/
    /*}*/

    /*@media screen and (min-width:1660px) and (max-width: 1679px) {*/
    /*    .joinUs .contentTitle_by .Subtract {*/
    /*        top: -28%;*/
    /*    }*/
    /*}*/

    /*@media screen and (min-width:1646px) and (max-width: 1659px) {*/
    /*    .joinUs .contentTitle_by .Subtract {*/
    /*        top: -26%;*/
    /*    }*/
    /*}*/

    /*@media screen and (min-width:1588px) and (max-width:1645px) {*/
    /*    .joinUs .contentTitle_by .Subtract {*/
    /*        top: -20%;*/
    /*    }*/
    /*}*/

    /*@media screen and (max-width:1587px) {*/
    /*    !*.joinUs .contentTitle_by .Subtract {*!*/
    /*    !*    display: none;*!*/
    /*    !*}*!*/
    /*}*/
.content {
    margin-top: 34px;
    white-space: pre-wrap;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #333333;
}

.data .number {
    font-family: 'DIN Alternate';
    margin-right: 0.1em;
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 65px;
    text-align: center;
    color: #49C5B6;
}

.data {
    margin-top: 34px;
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    width: 900px;
}

.data .unit {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 56px;
    /* identical to box height */
    text-align: center;
    color: #49C5B6;
}

.data .subTitle {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */
    text-align: center;
    color: #333333;
}

.tab-wrapper {
    margin-top: 34px;
    margin-bottom: 20px;
}

.card-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 34px;
    justify-content: space-evenly;
}


/*  */

.starResources-introduce .slick-swiper .slick-prev {
    left: -70px
}

.starResources-introduce .slick-swiper .slick-next {
    right: -70px;
}

.starResources-introduce .slick-swiper,
.starResources-introduce .slick-slide {
    padding: 10px!important;
}

.introduce-flex {
    width: calc(100% + 2px);
    background: #F6F6F6;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    border-radius: 20px;
}

.introduce-flex a {
    display: inline-block;
    text-decoration: none;
}

.introduce-flex-body {
    width: 100%;
    height: 386px;
    overflow: hidden;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
}

.introduce-flex-img {
    width: 100%;
    height: 280px;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
}

.introduce-flex-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.introduce-flex-content {
    padding: 15px;
}

.introduce-flex-content-name {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.introduce-flex-content-introduce {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    overflow: hidden;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.introduce-flex-content-img {
    width: 20px;
    height: 20px;
    margin-left: 4px;
}

.flexccc .video-react-big-play-button {
    display: none;
}

a:link {
    text-decoration:none;
}

.resources .nav-head-img{
    width: 100%;
    position: relative;
}
.resources .nav-head-img img{
    width: 100%;
    vertical-align: bottom;
}

.resources .nav-head-text {
    width: 216px;
    height: 66px;
}
.resources .nav-head-text img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.resources .nav-head-img div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) matrix(1, 0, -0.1, 1, 0, 0);
    font-family: HYRunYuan;
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 66px;
    /* identical to box height */
    color: #FFFFFF;
}

.resources .Subtract {
    position: absolute;
    z-index: -1;
    width: 45.65%;
    max-width: 1000px;
    right: 0;
    top: 20%;
}

.resources .Subtract.on {
    right: -10%;
    top: 50%;
    transition: all 4s ease-out;
}

/* .resources .Subtract {
    position: absolute;
    z-index: -1;
    width: 52.65%;
    max-width: 1000px;
    right: 0;
    top: 40%;
    transition: all 2s ease-out;
}

.resources .Subtract.on {
    right: -10%;
    top: 60%;
    transition: all 2s ease-out;
}
*/
.tab div.tab-choose {
    color: #fff;
}

.tab-active {
    width: 25%;
    height: 100%;
    border-radius: 57px;
    background: #333333;
    position: absolute;
    left: 0;
    transition: left 300ms;
}

.tab div {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    width: 126px;
    color: #333333;
    line-height: 46px;
    cursor: pointer;
}

.tab-item {
    position: relative;
    transition: color 600ms;
    z-index: 99;
}

.tab {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);
    border-radius: 57px;
    text-align: center;
    height: 46px;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 50%;
    transform: translateX(-50%);
    position: relative;
}
.introduce .content-wrapper {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 68px;
    position: relative;
}


/* .content-wrapper:last-child{
    position: relative;
} */

.img-qrcode {
    width: 129px;
    position: absolute;
    right: 50px;
    bottom: -250px;
    z-index: 999;
}

.img-qrcode img {
    width: 100%;
}

.content-wrapper .person-title {
    font-size: 34px;
    line-height: 48px;
    font-family: 'PingFang SC';
    font-weight: bold;
    /* identical to box height */
    color: #333333;
    display: flex;
    align-items: center;
}

.content-wrapper .bar {
    background: #6dc2b6;
    border-radius: 12px;
    height: 32px;
    width: 4px;
}

.person-title span {
    margin-left: 0.5em;
}

.introduce {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    color: #333333;
}

.introduce .introduce-wrapper {
    margin-top: 40px;
    display: flex;
}

.introduce .introduce-wrapper .main-img {
    width: 275px;
    height: 300px;
    border-radius: 20px;
    margin-right: 37px;
    position: relative;
    overflow: hidden;
}

.introduce .introduce-wrapper .main-img img {
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
  /*  position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);*/
}

.font-bold {
    font-weight: bold;
}

.introduce .introduce-wrapper .introduce-title {
    font-size: 24px;
    line-height: 34px;
    color: #333333;
    display: flex;
    margin-top: 20px;
}

.introduce-wrapper .introduce-title .title-tag {
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.01em;
    color: #333333;
    margin-left: 2em;
    background: #e8e8e8;
    border-radius: 30px;
    align-self: center;
    padding: 0 1em;
}

.person-property {
    width: 100px;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
}

.person-describe.part1 {
    display: flex;
}

.person-describe.part1 img {
    width: 20px;
    border-radius: 50%;
}

.part1>div {
    align-self: center;
}

.part1 img {
    vertical-align: text-top;
}

.person-describe.part1 span {
    font-family: DIN Alternate;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 122% */
    margin-left: 0.1em;
    text-align: center;
    color: #333333;
}

.person-describe.part1 div {
    border-radius: 30px;
    margin-right: 1em;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
}

.person-describe {
    font-size: 16px;
    line-height: 160%;
    /* or 26px */
    text-align: justify;
    white-space: pre-wrap;
    /* 黑/666666 */
    color: #666666;
}

.person-describe.part2 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    /* justify-content: flex-start;
    align-content: flex-start */
}

.person-describe .tag {
    line-height: 22px;
    border-radius: 30px;
    padding: 0 1em;
    margin-right: 1em;
    margin-bottom: 0.5em;
    color: #fff;
    font-family: PingFang SC;
    background: #333;
}

.person-describe .hot {
    width: 100%;
}


/* .part2 div {
    background: #333333;
    border-radius: 30px;
    padding: 0 1em;
    margin-right: 1em;
    height: 25px;
    line-height: 24px;
    color: #ffffff;
    font-family: PingFang SC;
}

.part2 {
    display: flex;
} */

.part3 .person-property {
    flex: none;
}

.part4 .person-property {
    flex: none;
}

.photoList {
    display: flex;
    margin-top: 40px;
    margin-right: -20px;
}

.photoList .img-wrapper {
    width: 286px;
    height: 382.25px;
    border-radius: 20px;
    filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.05));
    margin-right: 20px;
    position: relative;
    overflow: hidden;
}

.photoList .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.introduce .Subtract {
    position: absolute;
    width: 2000px;
    left: -5%;
    top:10%;
    z-index: 9;
}

.introduce .Subtract.on {
    left: -15%;
    top: 10%;
    transition: all 2s ease-out;
}

.introduce .Union {
    position: absolute;
    z-index: 0;
    width: 40.71%;
    left: 0;
    top: 15%;
}
.coBranding{
    background-color: #f6f6f6;
}
.coBranding .coBrandingTitle:nth-child(3){
    margin-top: 30px;
}
.coBranding .coBrandingTitle{
    font-family: PingFang SC;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    color: #333333;
}
.coBranding .coBrandingTitle + .coBrandingTitle{
    margin-top: 20px;
}

.coBranding .coBrandingMore {
    background-color: #333333;
    color: white;
    font-size: 14px;
    border-radius: 20px;
    width: 96px;
    height: 39px;   
    line-height: 39px;
    margin-top: 39px;
    cursor: pointer;
}

.coBranding .coBrandingMore:hover {
    background: #49C5B6;
}

.coBrandingFlow {
    background-color: transparent;
    width: 74.35%;
    height: auto;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.coBrandingFlow .flowline {
    width: 332px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;   
    padding: 0 5px;     
}

.coBrandingFlow .flowline .flowItem{
    width: 100%;
    overflow: hidden; 
    background-color: white;
    border-radius:24px;
    margin-bottom: 40px;
    box-shadow:0px 2px 6px #ebebeb;
}

.coBrandingFlow .flowline .flowItem img{ 
    width: 100%;
    margin: 0;
    padding: 0;
 }

 .coBrandingFlow .flowline .flowItem label{
     font-size: 20px;
     font-weight: bold;
     padding-left: 21px;
     padding-top: 10px;
     display: block;
     text-align: left;
     color: #333333;
 }

 .coBrandingFlow .flowline .flowItem p{
    padding:0 21px;
    text-align: left;
}

 .coBranding .contentTitle_by {
     z-index: 1;
 }

.coBranding .Subtract {
    position: absolute;
    z-index: -1;
    width: 48%;
    right: -40px;
    top: 38%;
}
.arcboard {
    width: 100%;
    background-position: center -430px;
    background-size: 1500px auto;
    position: relative;
    margin-top: 50px;
}

.arc-container {
    min-height: 500px;
    position: relative;
    margin: 0 auto;
}

.logo-item {
    width: 159px;
    height: 56px;
    padding: 0 10px;
    background: #333333;
    border-radius: 56px;
    position: absolute;
    left: 50%;
    bottom: 200px;
    transform: translateX(-50%);
}

.logo-item img {
    width: 80%;
    height: 90%;
    object-fit: contain;
}


/* .arc-container::before {
    content: '';
    display: block;
    width: 100%;
    background: linear-gradient(180deg, rgba(60, 207, 195, 0.8) 0%, rgba(196, 196, 196, 0) 21.35%);
    opacity: 0.3;
    top: 110px;
    height: 800px;
    position: absolute;
    border-radius: 50%;
} */

.arc-container .arc-group {
    width: 100%;
    position: relative;
    height: 360px;
}

.arc-container .arc-box {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: #fff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.05);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s linear;
}

.arc-container .arc-box.show {
    opacity: 1;
}

.arc-container .arc-box img {
    max-width: 70%;
    max-height: 45%;
}


.sharing .sharingTitle:nth-child(3){
    margin-top: 40px;
}
.sharing .sharingTitle{
    font-size: 20px;
    text-align: center;
    line-height: 34px;
    height: 34px;
    font-weight: 400;
    color: #333;
}
.sharing .sharingTitle + .sharingTitle{
    margin-top: 15px;
}
.sharing .sharingCount{
    margin-top: 83px;
    width: 828px;
    color: #333;
}
.sharing .sharingCount div label{
    font-size: 40px;
    text-align: center;
    color: #69c0b4;
    min-width: 100px;
    font-family: DIN Alternate;
    font-style: normal;
    font-weight: bold;
    line-height: 56px;
}
.sharing .sharingCount div label > .plus{
    font-weight: bold;
    font-size: 40px;
    padding-top: 0;
    vertical-align: top;
    font-family: PingFang SC;
    color: #49C5B6;
}
.sharing .sharingCount div label i{
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    position: relative;
    top:-3px;
    color: #49C5B6;
}
.sharing .sharingCount div span{
    padding-top: 15px;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
}
.sharing .sharingCount div span.number{
    padding-top: 0;
    font-size: 56px;
    font-weight: 500;
    line-height: 1;
    color: #49C5B6;
}

.sharing .moreFocuson{
    padding-top: 40px;
    width: 612px;
}

.sharing .moreFocuson div span{
    padding-top: 16px;
}

.sharing .more-image {
    width: 90px;
    height: 90px;
}

.sharing .cus-slice .slick-next {
    right: 61px;
}

.sharing .cover-text-desc {
    width: 62%;
}

.sharing .slick-swiper .slick-list {
    height: 530px;
}

.sharing .Union {
    position: absolute;
    z-index: -1;
    width: 67.71%;
    left: 0;
    top: -10%;
}

.sharing .Subtract {
    position: absolute;
    z-index: -1;
    width: 55%;
    right: -40px;
    top: -10%;
}

.gallery-container{
    display: flex;
    /* max-width: 1210px; */
    margin-top: 40px;
    flex-wrap: wrap;
    justify-content: center;
}

.gallery-container .gallery-box{
    /* width: 121px;
    height: 121px; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #f8f8f8;
    border-radius: 20px;
    box-shadow: 0px 1px 20px 4px rgba(0, 0, 0, 0.03);
    transition: all 0.2s linear;

}

.gallery-container .gallery-box:hover{
    background-color:#ffffff;
    box-shadow: 0 4px 40px 4px rgba(0, 0, 0, 0.13);
    position: relative;
    z-index: 99;
}


.gallery-container .gallery-box img{
    max-width: 70%;
    max-height: 45%;
}




.koc-container{
    width: 100%;
    margin-top: 40px;
    filter: blur(15px);
    background: #F6F6F6;
}

.koc-container .koc-row{
    width: 100%;
    position: relative;
    height: 120px;
}

.koc-container .koc-row+.koc-row{
    margin-top: 10px;
}

.koc-container .koc-box{
    display: inline-block;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s linear;
    -moz-user-select:none;/**ff*/
    -webkit-user-select:none;/**chrome、safari、360极速*/
}

.koc-container .koc-box img {
    width: 100px;
}

.koc-container .koc-row.offset{
    transform: translateX(78px);
}

.koc-container .koc-box.hide{
    opacity: 0;
}

.cs-contaienr {
    width: 1200px;
    margin: 60px auto 0 auto;
}

.cs-contaienr .slick-list {
    width: calc( 100vw - (100vw - 1200px) / 2);
}

.cs-contaienr .slick-swiper .slick-track{
    padding-top: 0;
}

.cs-contaienr .slick-swiper .slick-next{
    right: -25px;
}

.cs-contaienr .slick-swiper .slick-prev{
    left: -25px;
}


.cs-contaienr .slick-swiper .slick-list{
    height: auto;
}

.cs-contaienr .slick-slider .slick-prev, .cs-contaienr .slick-slider .slick-next{
    background: #333;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 100;
}

.cs-contaienr .cs-box {
    position: relative;
    width: 952px;
    display: flex;
    justify-content: space-between;
}

.cs-contaienr .cs-box img {
    border-radius: 22px;
}


.cs-contaienr .cs-item img {
    width: 687px;
}

.cs-contaienr .cs-item .right {
    width: 240px;
    text-align: left;
}

.cs-contaienr .right h3 {
    font-family: DIN Alternate;
    font-style: normal;
    font-weight: bold;
    font-size: 76px;
    line-height: 88px;
    /* vi青色 */
    color: #49C5B6;
    margin-top: 20px;
    margin-bottom: 13px;
}

.cs-contaienr .right h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    /* vi青色 */
    color: #333333;
    margin-bottom: 19px;
}

.cs-contaienr .right p {
    line-height: 160%;
    /* or 26px */
    text-align: justify;
    letter-spacing: 0.01em;
    color: #333333;
    font-size: 16px;
}

.cs-contaienr .cs-number {
    margin: 15px 0 0 0;
    padding: 0;
    text-align: left;
}

.cs-contaienr .cs-number li {
    list-style: none;
    display: inline-block;
    font-family: DIN Alternate;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
}

.cs-contaienr .cs-number li+li {
    margin-left: 23px;
}
.avatar-container {
    display: flex;
    max-width: 1110px;
    width: 1000px;
    margin-top: 56px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.avatar-container .avatar-box {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 40px;
    overflow: hidden;
    transition: all 0.2s linear;
    cursor: pointer;
}

.avatar-container .avatar-box:hover {
    background-color: #ffffff;
    box-shadow: 0 0 10px 1px rgb(0 0 0 / 13%);
    transform: scale(1.5);
}

.avatar-container .avatar-box img {
    width: 100%;
}
.marketing {
  background-color: #f6f6f6;
}

.marketing .anchor {
  margin-top: 40px;
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  width: 893px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
}

.marketing .anchor .count img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.marketing .anchor .count label {
  line-height: 31px;
  height: 31px;
  font-size: 20px;
}

.marketing .anchor .anchorTxt {
  color: #353535;
  font-size: 29px;
  margin-top: 46px;
  word-break: break-all;
  width: 540px;
  overflow: hidden;
  text-align: left;
  font-weight: 600;
}

.marketing .anchor .anchorTitle {
  height: 43px;
  line-height: 43px;
  font-weight: 600;
  background-color: #333333;
  color: #fff;
  border-radius: 30px;
  padding: 0 15px;
  font-size: 26px;
}

.marketing .anchor .anchorInfo {
  color: #666666;
  margin-top: 46px;
  margin-bottom: 36px;
  width: 540px;
  overflow: hidden;
  text-align: left;
  font-size: 15px;
}

.slick-swiper .slick-slide {
  padding: 0;
}

.marketing .anchor .header {
  border-radius: 20px;
  width: 300px;
  height: 400px;
  overflow: hidden;
  margin-right: 52px;
}

.marketing .nav-head-img{
  width: 100%;
  position: relative;
}
.marketing .nav-head-img img{
  width: 100%;
  vertical-align: bottom;
}

.marketing .nav-head-text {
  width: 216px;
  height: 66px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) matrix(1, 0, -0.1, 1, 0, 0);
}
.marketing .nav-head-text img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.marketing .anchor .header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.marketing .caseInfo {
  width: 1070px;
  margin-top: 40px;
}

.caseInfo .slick-slider.slick-initialized{
  width: 1070px;

}

.marketing .caseInfoItem {
  width: 332px;
  overflow: hidden;
  padding: 0 11px;
}

.marketing .caseInfoItem label {
  font-weight: 600;
  padding-top: 30px;
  padding-left: 20px;
  color: #333333;
}

.marketing .flexrss-count {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.marketing .label-num {
  font-weight: 700;
  font-size: 28px;
  color: #202020;
  font-family: DIN Alternate;
}

.marketing .caseInfoItem p {
  padding: 0 20px;
  text-align: left;
}

.marketing .caseInfoItem .img {
  overflow: hidden;
  border-radius: 26px;
  width: 332px;
  margin: 0;
  padding: 0;
}

.marketing .caseInfoItem .img img {
  width: 100%;
  height: 100%;
}

.caseInfo .slick-prev,
.caseInfo .slick-next {
  background: #333;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 100;
  opacity: 1;
}
.caseInfo .slick-prev:hover,
.caseInfo .slick-next:hover {
background: #49C5B6;
}
.caseInfo .slick-arrow.slick-prev{
  left: -53px;
  top: 220px;
}
.caseInfo .slick-arrow.slick-next{
  left: -53px;
  top: 250px;
  transform: rotateZ(180deg);
}
.caseInfo .slick-prev:before,
.caseInfo .slick-next:before {
  font-family: 'iconfont';
  content: '\E61D';
}

.marketing .marketingEnter {
  width: 243px;
  height: 57px;
  border-radius: 28px;
  background-color: #333333;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
}

.marketing .marketingEnter label {
  color: white;
  font-size: 20px;
}

.marketing .marketingEnter .slice {
  margin-left: 15px;
  margin-right: 9px;
  width: 41px;
  height: 41px;
}

.icon-slice:before {
  font-family: 'iconfont';
  content: '\E61D';
}

.marketing .slice {
  background: #49C5B6;
  color: #fff;
  transform: rotate(125deg);
}

.marketing .marketingEnter:hover .slice {
  animation: enterSpin 1 .25s linear;
  animation-fill-mode: forwards;
}

@keyframes enterSpin {
  from {
    transform: rotate(125deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.marketing .slick-swiper .slick-list {
  height: 565px!important;
  max-height: none!important;
}

.cooperation-item {
  width: 100%;
  height: 335px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  overflow: hidden;
}

.marketing .Union {
  position: absolute;
  z-index: -1;
  width: 67.71%;
  left: 0;
  top: -10%;
}

.marketing .Subtract {
  position: absolute;
  z-index: -1;
  width: 45%;
  right: -40px;
  top: -10%;
}

.marketing .contentTitle_by {
  z-index: 2;
}

.marketing .contact-box-flex {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marketing .contact-box-item {
  width: 300px;
}

 html,
 body,
 #root {
   height: 100%;
 }
 .none {
   display: none;
 }
 .hand {
   cursor: pointer;
 }
 .posr {
   position: relative;
 }
 .posa {
   position: absolute;
 }
 .mga {
   margin: auto;
 }
 .fill {
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
 }
 .ft12 {
   font-size: 12px;
 }
 .ft14 {
   font-size: 14px;
 }
 .ft15 {
   font-size: 15px;
 }
 .ft16 {
   font-size: 16px;
 }
 .ft18 {
   font-size: 18px;
 }
 .ft20 {
   font-size: 20px;
 }
 .ft22 {
   font-size: 22px;
 }
 .ft24 {
   font-size: 24px;
 }
 .ft25 {
   font-size: 25px;
 }
 .ft28 {
   font-size: 28px;
 }
 .ft30 {
   font-size: 30px;
 }
 .ft33 {
   font-size: 33px;
 }
 .ft34 {
   font-size: 34px;
 }
 .ft36 {
   font-size: 36px;
 }
 .ft42 {
   font-size: 42px;
 }
 .ft48 {
   font-size: 48px;
 }
 .flex1 {
   flex: 1 1;
 }
 .flex2 {
   flex: 2 1;
 }
 .flexrb {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
 }
 .flexrbc {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
 }
 .flexrac {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
 }
 .flexrcs {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: flex-start;
 }
 .flexrcc {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
 }
 .flexrce {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: flex-end;
 }
 .flexrbs-n {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
   flex-wrap: wrap;
 }
 .flexrbs {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: flex-start;
 }
 .flexrec {
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;
 }
 .flexrevc {
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
 }
 .flexrss {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-start;
   flex-wrap: wrap;
 }
 .flexrss-n {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-start;
   flex-wrap: nowrap;
 }
 .flexrsc {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
 }
 .flexrse {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-end;
 }
 .flexcac {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: center;
 }
 .flexcc {
   display: flex;
   flex-direction: column;
   justify-content: center;
 }
 .flexcb {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
 }
 .flexcbc {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
 }
 .flexcbe {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-end;
 }
 .flexcbs {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
 }
 .flexcee {
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: flex-end;
 }
 .flexcec {
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: center;
 }
 .flexccc {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }
 .flexccs {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
 }
 .flexcss {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
 }
 .flexcas {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: flex-start;
 }
 .flexcsc {
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
 }
 .flexcevc {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;
 }
 .wfull-noblock {
   width: 100%;
   margin: auto;
 }
 .wfull {
   width: 100%;
   margin: auto;
   display: block;
 }
 .tgl {
   text-align: left;
 }
 .tgc {
   text-align: center;
 }
 .tabc {
   display: 'table';
   margin: '0 auto';
 }
 .tgr {
   text-align: right;
 }

