
.awards-container{
    width: 1160px;
    margin: 40px auto 6px auto;
}

.awards-container .slick-list {
    width: calc( 100vw - (100vw - 1160px) / 2);
}

.awards-container .slick-swiper .slick-track{
    padding-top: 0;
}

.awards-container .slick-swiper .slick-next{
    right: -20px;
    top:200px;
}

.awards-container .slick-swiper .slick-prev{
    left: -20px;
    top:200px;
}


.awards-container .slick-swiper .slick-list{
    height: auto;
}

.awards-container .slick-slider .slick-prev, .cs-contaienr .slick-slider .slick-next{
    background: #333;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 100;
}
.awards-container .slick-slide +.slick-slide{
  margin-left: 41px;
}
  .awards-container .awardsItem {
    text-align: left;
    overflow: hidden;
    background-color: transparent;
  }

  .awards-container .awardsItem label{
    padding: 6px 10px 7px 10px;
    background-color: #49C5B6;
    font-size: 14px;
    color: white;
    border-radius: 20px;
      font-family: DIN Alternate;
      font-style: normal;
      font-weight: bold;
  }
  .awards-container .awardsItem p {
    line-height: 36px;
    display: block;
    text-align: left;
    font-size: 20px;
      color: #333;
   }
   .awards-container .awardsItem .img {
    overflow: hidden;
    border-radius: 12px;
    width: 100%;
    height: 391px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    background-color: #6dc2b6;
  }
  .awards-container .awardsItem .img img{
      width: 100%;
    height: 100%;
  }