.flow-carousel{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}
.flow-carousel .slick-list {
    padding: 0 3px;
}
.flow-carousel .slick-slider.slick-initialized{
    width: 1264px;

}
.flow-block{
    box-sizing: border-box;
}
.flow-carousel .flow-wrapper > div{
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    display: flex;
}
.flow-carousel .slick-prev,
.flow-carousel .slick-next {
    background: #333;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 100;
    opacity: 1;
}
.flow-carousel .slick-prev:hover ,
.flow-carousel .slick-next:hover {
    background: #49C5B6;
}
.flow-carousel .slick-arrow.slick-prev{
    left: -53px;
}
.flow-carousel .slick-arrow.slick-next{
    right: -53px;
}
.flow-carousel .slick-prev:before {
    font-family: 'iconfont';
    content: '\e61d';
}
.fc-arrow-wrapper{
    width: 55px;
    align-self: center;
    display: flex;
    justify-content: center;

}
.fc-arrow-wrapper .img{

    height: 13.78px;
}
.flow-carousel .slick-next:before {
    font-family: 'iconfont';
    content: '\e61a';
}
.flow-block{
    position: relative;
    width: 261px;
    height: 237px;

    background: #FFFFFF;
    /* 卡片投影 */

    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 25px 23px;
}
.flow-block .fc-title{
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height */

    letter-spacing: 0.01em;

    /* 黑/333333 */

    color: #333333;
}
.flow-block .fc-describe{
    font-size: 18px;
    line-height: 26px;
    text-align: justify;
    letter-spacing: 0.02em;
    margin-top: 14px;
    /* 黑/999999 */

    color: #999999;
}
.flow-block .fc-index{
    width: 70px;
    height: 47px;
    position: absolute;
    font-size: 50px;
    right: 23px;
    bottom: 25px;
}

.flow-block .fc-index img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}