.backtotop {
    position: fixed;
    bottom: 72px;
    right: 68px;
    animation: fadeIn 700ms ease-in-out 1s both;
    cursor: pointer;
    width: 60px;
    height: 60px;
    background: #49C5B6;
    border-radius: 100%;
    text-align: center;
    line-height: 60px;
    z-index: 99;
}
.backtotop:not(.hideColor):hover {
    background: #333333;
}
.backtotop:before{
    font-family: 'iconfont';
    content: '\E61A';
    transform: rotate(-90deg);
    color: #fff;
    display: block;
    font-size: 30px;
}