.online .nav-head-img{
    width: 100%;
    position: relative;
}
.online .nav-head-img > img{
    width: 100%;    
    vertical-align: bottom;
}
.online .nav-head-text {
    width: 216px;
    height: 66px;
}
.online .nav-head-text img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.online{
    box-sizing: border-box;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    color: #333333;
}
.online .nav-head-img div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) matrix(1, 0, -0.1, 1, 0, 0);
    font-family: HYRunYuan;
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 66px;
    /* identical to box height */
    color: #FFFFFF;
}
.online .ruhnn-nav{
    padding-left: 10%;
}
.online-plan{
    width: 80%;
    margin: 0 10%;
    white-space: pre-wrap;
}
.online-plan .plan-describe{
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.04em;
    margin-top: 34px;
    /* 黑/333333 */

    color: #333333;
}
.case-img-wrapper{
    margin-top: 34px;
    display: flex;
    justify-content: center;
}

.case-carousel{
    width: 1210px;
    position: relative;
    height: 570px;
}
.case-carousel .left-button,.case-carousel .right-button{
    z-index: 0;
    position: absolute;
    top: 50%;
    width: 40px;
    transform: translateY(-50%);
    cursor: pointer;
}
.case-carousel .left-button{
    left: 52px;
}
.case-carousel .right-button{
    right: 52px;
}
@keyframes left-click-top{
    from {
        left: 0px;
        width: 712px;
        top: 65px;
        height: 440px;
        /* alpha: 0.5; */
    }
    to{
        left: 145px;
        width: 920px;
        top: 0px;
        height: 570px;
        /* alpha: 1; */
    }
}
@keyframes left-click-left{
    from {
        left: 145px;
        width: 504px;
        top: 97px;
        height: 376px;
        /* alpha: 1 */
    }
    to{
        left: 0px;
        width: 712px;
        top: 65px;
        height: 440px;
        /* alpha: 0.5 */
    }
}
@keyframes left-click-right{
    from {
        right: 145px;
        width: 920px;
        top: 0px;
        height: 570px;
    }
    to{
        right: 0px;
        width: 712px;
        top: 65px;
        height: 440px;
        
    }
}
@keyframes right-click-right{
    from {
        right: 145px;
        width: 504px;
        top: 97px;
        height: 376px;
        alpha: 1
    }
    to{
        right: 0px;
        width: 712px;
        top: 65px;
        height: 440px;
        alpha: 0.5
    }
}
@keyframes right-click-left{
    from {
        left: 145px;
        width: 920px;
        top: 0px;
        height: 570px;
    }
    to{
        left: 0px;
        width: 712px;
        top: 65px;
        height: 440px;
    }
}
@keyframes right-click-top {
    from {
        left: 498px;
        width: 712px;
        top: 65px;
        height: 440px;
    }
    to{
        left: 145px;
        width: 920px;
        top: 0px;
        height: 570px;
    }
}

.clearfix::after{
    clear: both;
    content: "";
    display: block;
}
.case-carousel .carousel-img{
    display: none;
    position: relative;
}
.case-carousel .carousel-img img{
    width: 100%;
    border-radius: 20px;
}
.case-carousel .carousel-img.top{
    position: absolute;
    display: block;
    top: 0;
    left: 145px;
    width: 920px;
    height: 570px;
    z-index: -2;
    animation: leftMove 1s ease-out;
    animation-iteration-count:1;
}
.case-carousel .carousel-img.right-click-top{
    animation: right-click-top 1s ease-out;
    animation-iteration-count:1;

}
.case-carousel .carousel-img.left-click-top{
    animation: left-click-top 1s ease-out;
    animation-iteration-count:1;

}
.case-carousel .carousel-img.left{
    position: absolute;
    display: block;
    top: 65px;
    left: 0;
    width: 712px;
    height: 440px;
    z-index: -3;
}
.case-carousel .carousel-img.right-click-left{
    animation: right-click-left 1s ease-out;
    animation-iteration-count:1;
}
.case-carousel .carousel-img.left-click-left{
    animation: left-click-left 1s ease-out;
    animation-iteration-count:1;
}
.case-carousel .carousel-img.left .mask,.carousel-img.right .mask{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    background: rgba(255,255,255,0.5);
}
.case-carousel .carousel-img.right{
    position: absolute;
    display: block;
    top: 65px;
    right: 0;
    width: 712px;
    height: 440px;
    z-index: -3;
    animation: topMove 1s ease-out;
    animation-iteration-count:1;
}
.case-carousel .carousel-img.right-click-right{
    animation: right-click-right 1s ease-out;
    animation-iteration-count:1;
}
.case-carousel .carousel-img.left-click-right{
    animation: left-click-right 1s ease-out;
    animation-iteration-count:1;
}
.case-carousel .carousel-img.right{

}
.online-plan .tags{
    text-align: left;
    display: flex;
    justify-content: space-between;
}
.online-plan .tags .online-tag .online-title{
    font-family: DIN Alternate;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    /* identical to box height */
    letter-spacing: -0.005em;
    text-transform: capitalize;

    /* vi青色 */
    margin-top: 34px;

    color: #49C5B6;
}

.online-plan .tags .online-tag .online-name{
    /* 24号字 */

    font-family: PingFang SC;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    margin-top: 24px;

    /* 黑/333333 */

    color: #333333;
}
.online-plan .tags .online-tag .online-describe{
    font-family: PingFang SC;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    letter-spacing: 0.01em;

    /* 黑/999999 */

    color: #999999;
    margin-top: 14px;

}

.light-shop .shop-describe{
    
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 180%;
    /* or 36px */

    text-align: center;

    /* 黑/333333 */

    color: #333333;
    margin-top: 34px;
}

.icon-slice {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #333;
    color: #fff;
    font-size: 18px;
    border-radius: 100%;
    cursor: pointer;
}

.icon-slice:hover {
    background: #49C5B6;
}

.icon-slice:before {
    font-family: 'iconfont';
    content: '\E61D';
}

.icon-slice.right {
    transform: rotateZ(180deg);
}

.ml15 {
    margin-left: 15px;
}

.font-family-sc {
    font-family: 'PingFang SC';

}

.brand-list {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.brand-section {
    margin-bottom: 20px;
    padding: 0 10px;
    box-sizing: content-box;
}

.brand-section img {
    width: 165px;
    height: 72px;
    object-fit: contain;
}

.online .Union {
    position: absolute;
    z-index: -1;
    width: 67.71%;
    left: 0;
    top: -10%;
}

.online .Subtract {
    position: absolute;
    z-index: -1;
    width: 61%;
    right: -40px;
    top: -90%;
}

/*
.brand-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    justify-content: center;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

.brand-item {
    width: 15%;
    margin-bottom: 20px;
    padding: 0 3px;
    box-sizing: content-box;
}

.brand-list img {
    width: 185px;
    height: 92px;
    object-fit: contain;
}*/
